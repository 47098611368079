import React, { useState, useEffect, useContext } from "react";
import { Context } from "../App.jsx";
function CandidateListingComponent(props) {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  return (
    <div
      className="candidate-listing-component"
      onClick={() => props.onClick(props.index)}
    >
      <div className="candidate-item">
        <div className="img-container">
          <img src={props.image} alt="" />
        </div>
        <div className="name-container">{props.name}</div>
      </div>
    </div>
  );
}

export default CandidateListingComponent;
