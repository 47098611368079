import React, { useState, useContext, useEffect } from "react";
import { Context } from "../App.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function DropdownLocationFilter(props) {
  const [openMenu, setOpenMenu] = useState(null);
  const [startMenu, setStartMenu] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const { english } = useContext(Context);

  const toggleMenu = (menu) => {
    if (openMenu === menu) {
      setOpenMenu(null);
      setSelectedRegion(null); // Reset selection if closing
    } else {
      setOpenMenu(menu);
      setSelectedRegion(menu); // Set selected region
    }
  };

  const toggleStartMenu = () => {
    setStartMenu(!startMenu);
  };

  // Define the menu data with translations
  const menuData = {
    "NT West": {
      label: "新界西",
      locations: {
        "Kwai Chung": "葵涌",
        "Tsing Yi": "青衣",
        "Tsuen Wan": "荃灣",
        "Tuen Mun": "屯門",
        "Tin Shui Wai": "天水圍",
        "Yuen Long": "元朗",
      },
    },
    "NT East": {
      label: "新界東",
      locations: {
        "Tseung Kwan O": "將軍澳",
        "Sai Kung": "西貢",
        Shatin: "沙田",
        "Ma On Shan": "馬鞍山",
        "Tai Po": "大埔",
        粉嶺與上水: "粉嶺與上水",
      },
    },
    Kowloon: {
      label: "九龍",
      locations: {
        Hunghom: "紅磡",
        "Kowloon Tong": "九龍塘",
        "Kowloon City": "九龍城",
        "Kowloon Bay": "九龍灣",
        "Kwun Tong": "觀塘區",
        "Mei Foo": "美孚",
        "Sham Shui Po": "深水埗",
        "West Kowloon": "西九龍",
        "Wong Tai Sin": "黄大仙",
        "Yau Tsim Mong": "油尖旺",
      },
    },
    "Hong Kong Island": {
      label: "香港島",
      locations: {
        Aberdeen: "香港仔",
        Central: "中環",
        "Island East": "島東",
        "Island South": "島南",
        "Island West": "島西",
        "Mid Levels / Peak": "半山/山頂",
        "Pok Fu Lam": "薄扶林",
        "Wan Chai / Causeway Bay": "灣仔/銅鑼灣",
      },
    },
  };

  const getChineseLocation = (location) => {
    for (const region in menuData) {
      if (menuData[region].locations[location]) {
        return menuData[region].locations[location];
      }
    }
    return location; // Fallback to the original if not found
  };

  const handleLocationToggle = (location) => {
    const chineseLocation = getChineseLocation(location);
    setLocationList((prevList) => {
      if (prevList.includes(chineseLocation)) {
        return prevList.filter((item) => item !== chineseLocation);
      } else {
        return [...prevList, chineseLocation];
      }
    });
  };

  const handleRemoveLocation = (location) => {
    const chineseLocation = getChineseLocation(location);
    setLocationList((prevList) =>
      prevList.filter((item) => item !== chineseLocation)
    );
  };

  useEffect(() => {
    props.setLocationFilter(locationList);
  }, [locationList]);

  return (
    <div className="dropdown-container">
      <div className="location-container">
        {locationList.map((item) => (
          <div className="location-item" key={item}>
            <label htmlFor={item} onClick={() => handleRemoveLocation(item)}>
              {english
                ? Object.keys(menuData)
                    .flatMap((region) =>
                      Object.entries(menuData[region].locations)
                    )
                    .find(([_, value]) => value === item)?.[0] || item
                : item}
              <input type="checkbox" id={item} value={item} />
              <FontAwesomeIcon icon={faCircleXmark} />
            </label>
          </div>
        ))}
      </div>

      <div className="dropdown">
        <div className="dropdown-title" onClick={toggleStartMenu}>
          {english ? "Select your Preferred District" : "選擇您喜歡的工作地區"}
        </div>

        {startMenu && (
          <div className="dropdown-menu">
            {Object.keys(menuData).map((region) => (
              <div key={region}>
                <div
                  className={`dropdown-item ${
                    selectedRegion === region ? "selected" : ""
                  }`}
                  onClick={() => toggleMenu(region)}
                >
                  {english ? region : menuData[region].label}
                </div>
                {openMenu === region && (
                  <div className="submenu">
                    {Object.keys(menuData[region].locations).map((location) => (
                      <div
                        key={location}
                        onClick={() => handleLocationToggle(location)}
                        className={`submenu-item ${
                          locationList.includes(
                            menuData[region].locations[location]
                          )
                            ? "selected"
                            : ""
                        }`}
                      >
                        {english
                          ? location
                          : menuData[region].locations[location]}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div className="dropdown-buttons-container">
              <button onClick={toggleStartMenu}>Close</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DropdownLocationFilter;
