import React, { useState, useEffect, useContext } from "react";
import fakeImage from "../images/testimage2.jpg";
import EmployeeReviewCard from "./EmployeeReviewCard";
import axios from "axios";
import { Context } from "../App.jsx";

function EmployeeReviewComponent() {
  const [reviews, setReviews] = useState([]);
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const fakeData = [
    {
      companyName: "A1",
      companyImage: fakeImage,
      jobName: "Promotor",
      date: "15/04/24",
      rating: 4,
      compliments: ["Quick Learner", "Diligent", "On-Time"],
      reviewText:
        "Angela consistently demonstrates exceptional dedication and skill in their role. Their attention to detail and proactive approach enhance team productivity. Colleagues appreciate their supportive nature, fostering a positive work environment. Reliable and efficient, Angela is a valuable asset to the team, always willing to go the extra mile.",
    },
    {
      companyName: "B2",
      companyImage: fakeImage,
      jobName: "Software Developer",
      date: "20/04/24",
      rating: 5,
      compliments: ["Innovative", "Team Player", "Problem Solver"],
      reviewText:
        "John has a remarkable ability to tackle complex problems with innovative solutions. His collaboration with the team has led to successful project outcomes. John’s dedication and technical skills are invaluable to our success.",
    },
    {
      companyName: "C3",
      companyImage: fakeImage,
      jobName: "Marketing Specialist",
      date: "25/04/24",
      rating: 3,
      compliments: ["Creative", "Analytical", "Detail-Oriented"],
      reviewText:
        "Sophia brings a creative flair to the marketing team. Her analytical skills help in crafting effective campaigns. While there is room for growth in her strategic approach, her enthusiasm and willingness to learn are commendable.",
    },
    {
      companyName: "D4",
      companyImage: fakeImage,
      jobName: "Sales Manager",
      date: "30/04/24",
      rating: 4,
      compliments: ["Charismatic", "Goal-Oriented", "Persuasive"],
      reviewText:
        "Michael leads the sales team with charisma and a clear vision. His goal-oriented mindset drives the team towards exceeding targets. His persuasive communication skills have won over many clients, making him an asset to our organization.",
    },
    {
      companyName: "E5",
      companyImage: fakeImage,
      jobName: "Data Analyst",
      date: "05/05/24",
      rating: 5,
      compliments: ["Detail-Oriented", "Critical Thinker", "Efficient"],
      reviewText:
        "Emily exhibits exceptional analytical skills and attention to detail. Her ability to distill complex data into meaningful insights has significantly improved our decision-making processes. She is highly efficient and a pleasure to work with.",
    },
  ];
  useEffect(() => {
    axios.get("http://localhost:4000/reviews", config).then((res) => {
      console.log("Data from reviews ", res.data.review);
      setReviews(res.data.review);
    });
  }, []);

  return (
    <div className="EmployeeReviewComponent">
      <div className="title">Reviews</div>
      <div className="sub-title">From your Past Employers</div>
      <div className="review-card-container">
        {reviews &&
          reviews.map((item, index) => {
            const picture = `${apiUrl}/img/employers/logo/${item.employer.logo}`;
            return (
              <EmployeeReviewCard
                key={index}
                companyName={item.employer.companyName}
                companyImage={picture}
                jobName={item.job.jobTitle}
                date={item.job.date}
                rating={item.rating}
                compliments={item.compliment}
                reviewText={item.review}
              />
            );
          })}
      </div>
    </div>
  );
}

export default EmployeeReviewComponent;
