import React from "react";
import testimg from "../images/600x400.jpg";

function SelectedJobsComponent(props) {
  
  
  // Formats the date passed from props
  function formatDate(dateString) {
    // Parse the date string
    const date = new Date(dateString);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    // Format as dd/mm/yy
    return `${day}/${month}/${year}`;
  }

  // Example usage
  const formattedDate = formatDate(props.date);

  return (
    <div className="selected-jobs-component">
      <div className="selected-job-container">
        <div className="title">Selected Jobs</div>
        <div className="job-listing-container">
          <div className="img-container">
            <img src={props.image} alt="" />
          </div>
          <div className="text-container">
            <div className="job-title">{props.jobTitle}</div>
            <div className="information">{formattedDate} | {props.startTime} - {props.endTime} | ${props.pay}/hr</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectedJobsComponent;
