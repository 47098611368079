import React from "react";
import { useState, useEffect, useContext } from "react";

import DescriptorComponent from "./DescriptorComponent.jsx";
import axios from "axios";
import LanguageComponent from "./components/LanguageComponent.jsx";
import { Context } from "./App.jsx";
import { useNavigate, useLocation } from "react-router-dom";

function EditEmployerProfile() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [businessName, setBusinessName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState(null);

  const navigate = useNavigate();
  // Below is data from previous screen, their login details
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const multiFormConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const toBack = () => {
    navigate("/home/employer");
  };

  useEffect(() => {
    axios(apiUrl + "/employer/me", config).then((res) => {
      console.log(res);
      console.log(res.data.data.employer);
      const data = res.data.data.employer.details[0];
      console.log(data);
      setCompanyName(data.companyName);
      setBusinessName(data.legalBusinessName);
      setContactName(data.contactName);
      setContactNumber(data.contactNumber);
      setAddress(data.address);
    });
  }, []);

  const [finalData, setFinalData] = useState({
    companyName: "",
    legalBusinessName: "",
    contactName: "",
    contactNumber: "",
    logo: null,
    BR: null,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Activated Mouse Hover");
    console.log("This is the final Data from hover :", finalData);
  };

  const handleSubmitFinal = (e) => {
    e.preventDefault();
    navigate("/signup/employer", { state: finalData });
  };
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value);
    setFinalData((prev) => {
      return { ...prev, companyName: e.target.value };
    });
  };
  const handleFile = (e) => {
    setLogo(e.target.files[0]);
    console.log(e.target.files[0]);
    setFinalData((prev) => {
      return { ...prev, logo: e.target.files[0] };
    });
  };

  const handleBusinessName = (e) => {
    setBusinessName(e.target.value);
    setFinalData((prev) => {
      return { ...prev, legalBusinessName: e.target.value };
    });
  };

  const handleContactName = (e) => {
    setContactName(e.target.value);
    setFinalData((prev) => {
      return { ...prev, contactName: e.target.value };
    });
  };

  const handleContactNumber = (e) => {
    setContactNumber(e.target.value);
    setFinalData((prev) => {
      return { ...prev, contactNumber: Number(e.target.value) };
    });
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
    setFinalData((prev) => {
      return { ...prev, address: e.target.value };
    });
  };

  return (
    <div>
      <div className="create-profile-container">
        <LanguageComponent />
        <div className="title header-2">
          {english ? "Company Profile" : "公司檔案"}
        </div>
        <form action="">
          <div className="grid-container">
            <div className="row-item">
              <label htmlFor="company-name">
                {english ? "Company Name" : "業務名稱"}
              </label>
              <input
                type="text"
                onChange={handleCompanyName}
                value={companyName}
                name="company-name"
              />
            </div>
            <div className="row-item">
              <label htmlFor="business-name">
                {english ? "Legal Business Name" : "公司名稱"}
              </label>
              <input
                type="text"
                onChange={handleBusinessName}
                name="business-name"
                value={businessName}
              />
            </div>
            <div className="row-item">
              <label htmlFor="contact-name">
                {english ? "Contact Name" : "聯絡人姓名"}
              </label>
              <input
                type="text"
                onChange={handleContactName}
                name="contact-name"
                value={contactName}
              />
            </div>
            <div className="row-item">
              <label htmlFor="contact-number">
                {english ? "Contact Number" : "電話號碼"}
              </label>
              <input
                type="text"
                onChange={handleContactNumber}
                name="contact-number"
                value={contactNumber}
              />
            </div>
            <div className="row-item">
              <label htmlFor="address">
                {english ? "Address" : "公司地址"}
              </label>
              <input
                type="text"
                onChange={handleAddress}
                value={address}
                name="address"
              />
            </div>
            <div className="row-item">
              <label htmlFor="logo">{english ? "Logo" : "公司標記"}</label>
              <input type="file" onChange={handleFile} name="logo" />
            </div>
          </div>

          <button
            className="create-account-btn"
            onMouseEnter={handleSubmit}
            onClick={handleSubmitFinal}
          >
            {english ? "Done" : "完成"}
          </button>
        </form>
        <button className="back-btn" onClick={() => toBack()}>
          {english ? "Back" : "回去"}
        </button>
      </div>
    </div>
  );
}

export default EditEmployerProfile;
