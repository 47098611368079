import React, { useState, useEffect, useContext } from "react";
import { Context } from "../App.jsx";
import { Link } from "react-router-dom";

function WorkerListingComponent(props) {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [confirmedWorkerStatus, setConfirmedWorkerStatus] = useState(
    props.confirmed
  );
  const [candidateWorkerStatus, setCandidateWorkerStatus] = useState(
    props.candidate
  );
  const [rejectedWorkerStatus, setRejectedWorkerStatus] = useState(
    props.rejected
  );

  return (
    <div className="WorkerListingComponent">
      <div className="worker-listing-item">
        <div className="img-container">
          <img src={`${apiUrl}/img/users/${props.userPhoto}`} alt="" />
        </div>
        <div className="name-container">
          {props.firstName} {props.lastName}
        </div>
        <Link
          className="candidate-anchor"
          to={
            props.workshop
              ? "/employer/view/workshop/candidates-detail"
              : "/employer/view/candidates-detail"
          }
          state={{
            userID: props.userID,
            jobID: props.jobID,
            workshopID: props.workshopID,
            confirmedWorkerStatus,
            candidateWorkerStatus,
            rejectedWorkerStatus,
          }}
        >
          <button className="view-details-btn">View Details</button>
        </Link>
      </div>
    </div>
  );
}

export default WorkerListingComponent;
