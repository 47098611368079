import React from "react";
import Navbar from "./components/Navbar";
import UserProfileCard from "./components/UserProfileCard";
import EmployeeReviewComponent from "./components/EmployeeReviewComponent";

function UserReviews() {
  return (
    <div className="UserReviews">
      <Navbar />
      <div className="profile-container">
        <UserProfileCard />
        <EmployeeReviewComponent />
      </div>
    </div>
  );
}

export default UserReviews;
