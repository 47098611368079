import React, { useState, useEffect, useContext } from "react";
import { Context } from "./App.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import NavbarEmployer from "./components/NavbarEmployer.jsx";
import SelectedJobsComponent from "./components/SelectedJobsComponent.jsx";
import profileDefault from "./images/profileDefault.jpg";
import CandidateListingComponent from "./components/CandidateListingComponent.jsx";
import testimage1 from "./images/testimage1.jpg";
import testimage2 from "./images/testimage2.jpg";
import testimage3 from "./images/testimage3.jpg";
import testimage4 from "./images/testimage4.jpg";

function ViewWorkshopSignupDetails() {
  // States
  const [selectedJob, setSelectedJob] = useState();
  const [appliedCandidates, setAppliedCandidates] = useState();
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [candidatePhoto, setCandidatePhoto] = useState();
  const [bestFive, setBestFive] = useState([]);
  const [pastJobs, setPastJobs] = useState([]);
  const [userID, setUserID] = useState();
  const companyPic = localStorage.getItem("profilePic");
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  // use Location
  const location = useLocation();
  const jobID = location.state.jobID;
  const workshopID = location.state.workshopID;
  const confirmedWorkerStatus = location.state.confirmedWorkerStatus;
  const candidateWorkerStatus = location.state.candidateWorkerStatus;
  const rejectedWorkerStatus = location.state.rejectedWorkerStatus;
  // Debugging for useLocation Logs
  // console.log("confirmed worker status: ", confirmedWorkerStatus);
  // console.log("candidate worker status: ", candidateWorkerStatus);
  // console.log("rejected worker status: ", rejectedWorkerStatus);

  // useNavigate Intialisation
  const navigate = useNavigate();

  const toViewEmployee = () => {
    navigate("http://localhost:3000/employer/view/candidates");
  };
  console.log(userID);
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };

  useEffect(() => {
    axios
      .get(apiUrl + `/workshop/${workshopID}/candidates`, config)
      .then((res) => {
        console.log("getting from workshop/workshopID", res.data.data);
        setSelectedJob(res.data.data.workshop);
        setAppliedCandidates(res.data.data.applied);
        // Initial Display here
        setBestFive(res.data.data.applied[0].userDetails[0].descriptors);
        setPastJobs(res.data.data.applied[0].userDetails[0].pastExperience);
        setCandidatePhoto(res.data.data.applied[0].userDetails[0].userPhoto);
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(apiUrl + `/job/${jobID}/candidates`, config)
  //     .then((res) => {
  //       console.log("RES DATA DATA HERE: ", res.data.data);
  //       console.log(res.data.data.applied);
  //       console.log(res.data.data.applied[0].UserInfo);
  //       console.log(res.data.data.applied[0].userDetails);
  //       console.log(res.data.data.job);
  //       setSelectedJob(res.data.data.job);
  //       setAppliedCandidates(res.data.data.applied);
  //       // Initial Display here
  //       setBestFive(res.data.data.applied[0].userDetails[0].descriptors);
  //       setPastJobs(res.data.data.applied[0].userDetails[0].pastExperience);
  //       // Sets the default photo of the candidate
  //       console.log(
  //         "This is the candidate photo: ",
  //         res.data.data.applied[0].userDetails[0].userPhoto
  //       );
  //       setCandidatePhoto(res.data.data.applied[0].userDetails[0].userPhoto);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [jobID]);

  // Makes sure the default value is the first array on load. Initial
  useEffect(() => {
    console.log("This is applied Candidates: ", appliedCandidates);
    if (appliedCandidates) {
      setCandidateInfo(appliedCandidates[0].userDetails[0]);
    }
  }, [appliedCandidates]);

  // Sets userID to the selected user ID for Axios PATCH below
  useEffect(() => {
    if (candidateInfo) {
      console.log(candidateInfo);
      setUserID(candidateInfo.id);
    }
  }, [candidateInfo]);

  function handleCandidateInfo(index) {
    // setCandidateInfo(fakeData[index]);
    console.log("This is applied candidate ", appliedCandidates);
    console.log(appliedCandidates[index].userDetails[0]);
    setCandidateInfo(appliedCandidates[index].userDetails[0]);

    console.log(
      "This is applied candidate descriptors: ",
      appliedCandidates[index].userDetails[0].descriptors
    );
    console.log(
      "This is applied candidate pastJob: ",
      appliedCandidates[index].userDetails[0].pastExperience
    );
    // This stores Descriptors and Past Jobs
    setBestFive(appliedCandidates[index].userDetails[0].descriptors);
    setPastJobs(appliedCandidates[index].userDetails[0].pastExperience);

    // Handles the candidate photo
    setCandidatePhoto(appliedCandidates[index].userDetails[0].userPhoto);
  }

  function handleAccept() {
    console.log(
      "patching to ",
      apiUrl + `/job/${jobID}/user/${userID}/confirm`
    );
    axios
      .patch(
        apiUrl + `/workshop/${workshopID}/user/${userID}/confirm`,
        {},
        config
      )
      .then((res) => {
        console.log("Successfully PATCHED success");
        navigate(-1, {

          state: { jobID }, // Pass jobID through state
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handleReject() {
    console.log(config);
    console.log(
      "patching to ",
      apiUrl + `/workshop/${workshopID}/user/${userID}/reject`
    );
    axios
      .patch(
        apiUrl + `/workshop/${workshopID}/user/${userID}/reject`,
        {},
        config
      )
      .then((res) => {
        console.log("Successfully PATCHED reject");
        navigate(-1, {
          state: { jobID }, // Pass jobID through state
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="view-candidate-details">
      <NavbarEmployer />
      <div className="grid-container">
        {selectedJob ? (
          <SelectedJobsComponent
            jobTitle={selectedJob.eventName}
            date={selectedJob.date}
            startTime={selectedJob.startTime}
            endTime={selectedJob.endTime}
            pay={selectedJob.fee}
            image={apiUrl + `/img/employers/logo/${companyPic}`}
          />
        ) : null}
        <div className="profile-container">
          <div className="top-container">
            <div className="img-container">
              <img
                src={candidatePhoto && apiUrl + `/img/users/${candidatePhoto}`}
                alt=""
              />
            </div>
            <div className="name">
              {candidateInfo
                ? candidateInfo.firstName + " " + candidateInfo.lastName
                : "Name not available"}
            </div>
          </div>
          <div className="mid-container">
            <div className="left">
              <div className="title">Descriptors</div>
              <div className="best-five-container">
                {bestFive && bestFive.length > 0 ? (
                  bestFive.map((item, index) => (
                    <div className="best-five-item" key={index}>
                      {item}
                    </div>
                  ))
                ) : (
                  <div>No descriptors available.</div> // Fallback content
                )}
              </div>
            </div>
            <div className="right">
              <div className="title">Past Job(s)</div>
              <div className="past-jobs-container">
                {pastJobs && pastJobs.length > 0 ? (
                  pastJobs.map((item, index) => (
                    <div className="past-job-item" key={index}>
                      <div className="job-title">{item.jobTitle}</div>
                      <div className="date-container">
                        <div className="sub-title">Date</div>
                        <div className="date">
                          {item.startDate} - {item.endDate}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No past jobs available.</div> // Fallback content
                )}
              </div>
            </div>
          </div>
          <div className="bottom-container">
            <button className="reject" onClick={() => handleReject()}>
              Reject
            </button>
            <button className="accept" onClick={() => handleAccept()}>
              Accept
            </button>
          </div>
        </div>
        <div className="candidates-container">
          <div className="title">Candidates</div>
          {appliedCandidates &&
            appliedCandidates.map((item, index) => {
              return (
                <CandidateListingComponent
                  key={index}
                  name={
                    item.userDetails[0].firstName +
                    " " +
                    item.userDetails[0].lastName
                  }
                  index={index}
                  onClick={handleCandidateInfo}
                  image={apiUrl + `/img/users/${candidatePhoto}`}
                />
              );
            })}
          {/* {fakeData &&
            fakeData.map((item, index) => {
              return (
                <CandidateListingComponent
                  key={index}
                  index={index}
                  image={item.profilePic}
                  name={item.name}
                  onClick={handleCandidateInfo}
                />
              );
            })} */}
        </div>
      </div>
    </div>
  );
}

export default ViewWorkshopSignupDetails;
