import React, { useState, useContext, useEffect } from "react";
import { Context } from "../App.jsx";
import StarsDisplay from "./StarsDisplay.jsx";
import { useNavigate, redirect } from "react-router-dom";
import axios from "axios";

function UserProfileCard(props) {
  const [profilePic, setProfilePic] = useState();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    preferredDistrict: [],
    languages: [],
    descriptors: [],
    reviewQuantity: 0,
  });
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const navigate = useNavigate();
  const userPhoto = localStorage.getItem("userPhoto");
  const userPhotoUrl = `${apiUrl}/img/users/${userPhoto}`;

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },
  };
  useEffect(() => {
    axios.get(`${apiUrl}/user/me`, config).then((res) => {
      console.log("Data from /user/me", res.data.data);
      setUserData(res.data.data.users);
    });
  }, []);

  const totalRowData = [
    "聆聽他人",
    "適應變化",
    "換位思考",
    "責任心",
    "系統思考",
    "溝通協作",
    "耐性",
    "時間管理",
    "開放思",
    "有愛心",
    "數據錄入",
    "寫作",
    "簡報製作",
    "檔案管理",
    "烘焙",
    "清潔",
    "育兒",
    "客戶服務",
  ];

  const totalRowDataEng = [
    "Listening to Others",
    "Adaptability",
    "Empathy",
    "Responsibility",
    "Systems Thinking",
    "Communication and Collaboration",
    "Patience",
    "Time Management",
    "Open-mindedness",
    "Compassion",
    "Data Entry",
    "Writing",
    "Presentation Creation",
    "File Management",
    "Baking",
    "Cleaning",
    "Childcare",
    "Customer Service",
  ];

  function toEditProfile() {
    navigate("/user/edit/profile");
  }

  return (
    <div className="UserProfileCard">
      <div className="left">
        <div className="image">
          <img src={userPhotoUrl} alt="" />
          {/* <img src={userPhoto} alt="" /> */}
        </div>
        <div className="header">
          {userData && userData.firstName} {userData && userData.lastName}
        </div>
        <div className="text-container"></div>
        <div className="star-rating">
          <StarsDisplay rating={userData.ratingsAverage} />
          <div className="reviews">
            (<a href="/user/reviews">{userData.ratingsQuantity} Reviews</a>)
          </div>
        </div>
        <div className="sub-header">
          {english ? "Personal Information" : "個人資料"}
        </div>
        <div className="grid-container">
          <div className="grid-item">
            <div className="grey-body">{english ? "Email" : "電郵地址"}</div>
            <div className="body-text">{userData.email}</div>
          </div>
          <div className="grid-item">
            {" "}
            <div className="grey-body">{english ? "Phone" : "電話號碼"}</div>
            <div className="body-text">{userData.phoneNumber}</div>
          </div>
          <div className="grid-item">
            {" "}
            <div className="grey-body">
              {english ? "Preferred Location" : "偏好地點"}
            </div>
            <div className="body-text">
              {userData && userData.preferredDistrict.join(" | ")}
            </div>
          </div>
          <div className="grid-item">
            <div className="grey-body">{english ? "Language" : "語言"}</div>
            <div className="body-text">
              {userData.languages && userData.languages.join(" | ")}
            </div>
          </div>
        </div>

        <div className="sub-header">
          {english ? "Best 5 Descriptor" : "5個最能代表您的形容詞"}
        </div>
        <div className="descriptor-container">
          {userData.descriptors.map((item) => {
            // Find the index of the item in totalRowData
            const index = totalRowData.indexOf(item);
            // Get the English translation using the index
            const displayItem =
              english && index !== -1 ? totalRowDataEng[index] : item;

            return (
              <div key={item} className="descriptor-item">
                {displayItem}
              </div>
            );
          })}
        </div>
        <div className="edit-button-container">
          <button onClick={() => toEditProfile()}>
            {english ? "Edit Profile" : "改個人資料"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserProfileCard;
