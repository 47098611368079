import React from "react";
import { useState, useEffect, useContext } from "react";
import { Context } from "./App.jsx";

function DescriptorComponent(props) {
  var descriptionItem = "";
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);

  // const handleChildChange = (e) => {
  //   if (props.bestFive.length == 0) {
  //     console.log("checked", e.target.value);
  //     props.setBestFive([...props.bestFive, e.target.value]);
  //   } else if (props.bestFive.length < 5) {
  //     for (let i = 0; i < props.bestFive.length; i++) {
  //       if (props.bestFive[i] != e.target.value) {
  //         setTimeout(
  //           props.setBestFive([...props.bestFive, e.target.value]),
  //           100
  //         );
  //         console.log("checked", e.target.value);
  //       } else {
  //         // props.setBestFive(...descriptionItem)

  //         console.log("unchecked", e.target.value);

  //         props.setBestFive([
  //           ...props.bestFive.filter((word) => word != e.target.value),
  //         ]);
  //       }
  //     }
  //   } else if (props.bestFive.length >= 5) {
  //     for (let i = 0; i < props.bestFive.length; i++) {
  //       if (props.bestFive[i] != e.target.value) {
  //         alert("You have selected 5 descriptors");
  //       } else {
  //         props.setBestFive([
  //           ...props.bestFive.filter((word) => word != e.target.value),
  //         ]);
  //       }
  //     }
  //   }
  // };

  const handleChildChange = (e) => {
    if (props.bestFive.length == 0) {
      console.log("Length 0");
      props.setBestFive([...props.bestFive, e.target.value]);
    } else if (props.bestFive.length < 5) {
      if (props.bestFive.includes(e.target.value)) {
        console.log("Includes Value");
        props.setBestFive([
          ...props.bestFive.filter((word) => word != e.target.value),
        ]);
      } else {
        console.log("Does not include value");
        props.setBestFive([...props.bestFive, e.target.value]);
      }
    } else {
      if (props.bestFive.includes(e.target.value)) {
        props.setBestFive([
          ...props.bestFive.filter((word) => word != e.target.value),
        ]);
      } else {
        console.log("BEST FIVE REACHED LENGTH 5");
        alert(english ? "Please Remove a Descriptor" : "請剷除一個形容詞");
      }
    }
  };

  return (
    <div className="text-item">
      <label htmlFor={props.id}>
        <input
          type="checkbox"
          id={props.id}
          value={props.value}
          onClick={handleChildChange}
          className={props.activate}
        />
        <span className={props.activate}>
          {english ? props.displayEngData : props.displayChiData}
        </span>
      </label>
    </div>
  );
}

export default DescriptorComponent;
