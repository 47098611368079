import React from "react";
import { useState, useEffect, useContext } from "react";
import DescriptorComponent from "./DescriptorComponent";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import LanguageComponent from "./components/LanguageComponent.jsx";
import { Context } from "./App.jsx";
import InputOptionComponent from "./components/InputOptionComponent.jsx";

function SignUpEmployee() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const navigate = useNavigate();
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const multiFormConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  // Row Data for Best Five
  const rowOneData = [
    "聆聽他人",
    "適應變化",
    "換位思考",
    "責任心",
    "系統思考",
    "溝通協作",
    "耐性",
    "時間管理",
    "開放思",
    "有愛心",
  ];
  const rowOneDataEng = [
    "Listening to Others",
    "Adaptability",
    "Empathy",
    "Responsibility",
    "Systems Thinking",
    "Communication and Collaboration",
    "Patience",
    "Time Management",
    "Open-mindedness",
    "Compassion",
  ];

  const rowTwoData = [
    "數據錄入",
    "寫作",
    "簡報製作",
    "檔案管理",
    "烘焙",
    "清潔",
    "育兒",
    "客戶服務",
  ];

  const rowTwoDataEng = [
    "Data Entry",
    "Writing",
    "Presentation Creation",
    "File Management",
    "Baking",
    "Cleaning",
    "Childcare",
    "Customer Service",
  ];

  // Used for Translating Best Five
  const totalRowData = [
    "聆聽他人",
    "適應變化",
    "換位思考",
    "責任心",
    "系統思考",
    "溝通協作",
    "耐性",
    "時間管理",
    "開放思",
    "有愛心",
    "數據錄入",
    "寫作",
    "簡報製作",
    "檔案管理",
    "烘焙",
    "清潔",
    "育兒",
    "客戶服務",
  ];

  const totalRowDataEng = [
    "Listening to Others",
    "Adaptability",
    "Empathy",
    "Responsibility",
    "Systems Thinking",
    "Communication and Collaboration",
    "Patience",
    "Time Management",
    "Open-mindedness",
    "Compassion",
    "Data Entry",
    "Writing",
    "Presentation Creation",
    "File Management",
    "Baking",
    "Cleaning",
    "Childcare",
    "Customer Service",
  ];

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [bestFive, setBestFive] = useState([]);
  const [activeItems, setActiveItems] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [pastJob, setPastJob] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [preferredIndustry, setPreferredIndustry] = useState([]);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [address, setAddress] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [finalData, setFinalData] = useState({
    preferredDistrict: [],
    languages: [],
    phoneNumber: null,
    descriptors: [],
    pastExperience: [{ jobTitle: "", startDate: "", endDate: "" }],
    preferredIndustry: [],
  });
  // Used for Translating Languages
  const languagesEnglish = ["Cantonese", "Mandarin", "English"];
  const languagesChinese = ["廣東話", "普通話", "英文"];

  // Used for Translating Districts
  const districtEnglish = [
    "Islands",
    "Kwai Tsing",
    "North",
    "Sai Kung",
    "Sha Tin",
    "Tai Po",
    "Tsuen Wan",
    "Tuen Mun",
    "Yuen Long",
    "Kowloon City",
    "Kwun Tong",
    "Sham Shui Po",
    "Wong Tai Sin",
    "Yau Tsim Mong",
    "Central and Western",
    "Eastern",
    "Southern",
    "Wan Chai",
  ];

  const districtChinese = [
    "離島區",
    "葵青區",
    "北區",
    "西貢區",
    "沙田區",
    "大埔區",
    "荃灣區",
    "屯門區",
    "元朗區",
    "九龍城區",
    "觀塘區",
    "深水埗區",
    "黃大仙區",
    "油尖旺區",
    "中西區",
    "東區",
    "南區",
    "灣仔區",
  ];

  const jobCategoryEng = ["Cleaning", "Logistics", "Food & Beverage", "Retail"];

  const jobCategoryChi = ["清潔", "物流及運輸", "餐飲", "零售"];

  const handleFile = (e) => {
    setProfilePic(e.target.files[0]);
    console.log(e.target.files[0]);
  };

  const handleJobType = (e) => {
    if (e.target.name === "job") {
      setJobTitle(e.target.value);
    } else if (e.target.name === "startYear") {
      setStartDate(e.target.value);
    } else if (e.target.name === "endYear") {
      setEndDate(e.target.value);
    }
  };

  const jobSubmit = (e) => {
    e.preventDefault();
    setModal2(!modal2);
    setPastJob([
      ...pastJob,
      { jobTitle: jobTitle, startDate: startDate, endDate: endDate },
    ]);
  };

  // Handling Phone Number
  const handlePhone = (e) => {
    setPhoneNumber(e.target.value);
  };

  // Handling Languages
  const handleLanguages = (e) => {
    if (e.target.checked) {
      setLanguages([...languages, e.target.value]);
      console.log("CHECKED", e.target.value);
    } else {
      setLanguages([...languages.filter((item) => item != e.target.value)]);
      console.log("Unchecked", e.target.value);
    }
  };
  // Handling Languages
  const handleLanguages2 = (e) => {
    if (languages.includes(e.target.value)) {
      console.log("Language already exists");
      document.getElementById("preferredLanguages").selectedIndex = 0;
    } else {
      setLanguages([...languages, e.target.value]);
      document.getElementById("preferredLanguages").selectedIndex = 0;
    }
  };

  const removeLanguages = (e) => {
    setLanguages([...languages.filter((item) => item != e.target.value)]);
  };

  const removePastJobs = (e) => {
    console.log("REMOVING THIS JOB: ", e.target.value)
    setPastJob([...pastJob.filter((item) => item.jobTitle != e.target.value)]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Activated Mouse Hover");
    console.log("This is the final Data from hover :", finalData);
  };

  const handleSubmitFinal = (e) => {
    e.preventDefault();
    axios
      .post(apiUrl + "/user/details", finalData, multiFormConfig)
      .then((res) => {
        // console.log(finalData);
        console.log("SIGN UP SUCCEEDED");
        navigate("/employee/job");
      })
      .catch((err) => {
        // console.log(finalData);
        console.log(config, "Failed");
        alert(
          "Sign Up Failed, Please Check All the Fields are Inputted Correctly"
        );
      });
    console.log(finalData);
  };

  // Handling Location
  const handleLocation = (e) => {
    if (preferredLocation.includes(e.target.value)) {
      document.getElementById("preferredLocation").selectedIndex = 0;
      console.log("location exists");
    } else {
      setPreferredLocation([...preferredLocation, e.target.value]);
      document.getElementById("preferredLocation").selectedIndex = 0;
    }
  };
  const handleRemoveLocation = (e) => {
    setPreferredLocation([
      ...preferredLocation.filter((item) => item != e.target.value),
    ]);
  };

  // Handling Industry
  const handleIndustry = (e) => {
    if (preferredIndustry.includes(e.target.value)) {
      console.log("Industry already selected");
      document.getElementById("industry").selectedIndex = 0;
    } else {
      setPreferredIndustry([...preferredIndustry, e.target.value]);
      document.getElementById("industry").selectedIndex = 0;
    }
  };
  const handleRemoveIndustry = (e) => {
    setPreferredIndustry([
      ...preferredIndustry.filter((item) => item != e.target.value),
    ]);
    setFinalData((prev) => {
      return { ...prev, preferredIndustry: [...preferredIndustry] };
    });
  };

  useEffect(() => {
    setFinalData((prev) => {
      return { ...prev, languages: languages };
    });
    // Setting preferredDistrict
    setFinalData((prev) => {
      return { ...prev, preferredDistrict: [...preferredLocation] };
    });
    // Setting Phone Number
    setFinalData((prev) => {
      return { ...prev, phoneNumber: Number(phoneNumber) };
    });
    // Setting Preferred Industry
    setFinalData((prev) => {
      return { ...prev, preferredIndustry: [...preferredIndustry] };
    });
    // Setting Previous Jobs
    setFinalData((prev) => {
      return { ...prev, pastExperience: [...pastJob] };
    });
    // Setting Descriptors
    setFinalData((prev) => {
      return { ...prev, descriptors: [...bestFive] };
    });
    setFinalData((prev) => {
      return { ...prev, userPhoto: profilePic };
    });
  }, [
    profilePic,
    bestFive,
    address,
    pastJob,
    preferredIndustry,
    phoneNumber,
    preferredLocation,
    languages,
  ]);

  const toggleModal2 = (e) => {
    e.preventDefault();
    setModal2(!modal2);
  };

  const toggleModal = (e) => {
    e.preventDefault();
    setModal(!modal);
  };

  const divToggle = () => {
    setModal(modal);
    console.log("clicked");
  };
  const pastOverlay = (
    <div className="div">
      <div className="overlay" onClick={toggleModal2}></div>
      <div className="white-container">
        <div className="row first-row">
          <div className="header-1">過往工作經驗</div>
        </div>
        <div className="row">
          <div className="row-item">
            <label htmlFor="job-type">工作類別</label>
            <input
              type="text"
              name="job"
              id="job-type"
              onChange={handleJobType}
            />
          </div>
          <div className="row-item">
            <label htmlFor="startYear">開始年份</label>
            <input
              type="month"
              name="startYear"
              id="startYear"
              onChange={handleJobType}
            />
          </div>
          <div className="row-item">
            <label htmlFor="endYear">結束年份</label>
            <input
              type="month"
              name="endYear"
              id="endYear"
              onChange={handleJobType}
            />
          </div>
        </div>
        <div className="row last-row">
          <div className="row-item">
            {" "}
            <button className="past-experience-button" onClick={jobSubmit}>
              完成
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Overlay for Best Five
  const overlay = (
    <div>
      <div className="overlay" onClick={toggleModal}></div>
      <div className="overlay-container">
        <div className="top">
          <button className="back-button" onClick={toggleModal}>
            {" "}
            {english ? "Back" : "回去"}{" "}
          </button>
          <div className="header-2">
            {english
              ? "Choose your best 5 descriptors"
              : "揀五個適合自己的形容詞"}
          </div>
        </div>
        <div className="wrapper">
          {" "}
          <div className="header-3">
            {english ? "Personal Traits: " : "個人特質："}
          </div>
          <div className="text-container">
            {rowOneData.map((item, index) => (
              <DescriptorComponent
                key={item}
                id={item}
                value={item}
                setBestFive={setBestFive}
                bestFive={bestFive}
                displayEngData={rowOneDataEng[index]}
                displayChiData={item}
                activate={
                  bestFive.includes(item) ? "activated" : "not-activated"
                }
              />
            ))}
          </div>
        </div>
        <div className="wrapper">
          {" "}
          <div className="header-3">
            {english ? "Technical Skills: " : "技術能力："}
          </div>
          <div className="text-container">
            {rowTwoData.map((item, index) => (
              <DescriptorComponent
                key={item}
                id={item}
                value={item}
                setBestFive={setBestFive}
                bestFive={bestFive}
                displayEngData={rowTwoDataEng[index]}
                displayChiData={item}
                activate={
                  bestFive.includes(item) ? "activated" : "not-activated"
                }
              />
            ))}
          </div>
        </div>
        <div className="done-button-container">
          <button onClick={toggleModal}>{english ? "Done" : "完成"}</button>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="create-profile-container">
        {modal ? overlay : null}
        {modal2 ? pastOverlay : null}
        <LanguageComponent />
        <div className="title header-2">
          {english ? "Create My Profile" : "建立我的個人檔案"}
        </div>
        <form action="">
          <div className="grid-container">
            <div className="row-item">
              <label htmlFor="">
                {english ? "Preferred Location" : "偏好地點 (可選擇多過一個）"}
              </label>
              {preferredLocation.map((item) => {
                // Find the index of the item in totalRowData
                const index = districtChinese.indexOf(item);
                // Get the English translation using the index
                const displayItem =
                  english && index !== -1 ? districtEnglish[index] : item;

                return (
                  <div className="industry-container flex">
                    <div className="industry">{displayItem}</div>
                    <div className="remove">
                      {" "}
                      <label htmlFor={item} onClick={handleRemoveLocation}>
                        <input type="checkbox" id={item} value={item} />
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </label>
                    </div>
                  </div>
                );
              })}
              <select
                name="preferred-location"
                id="preferredLocation"
                onChange={handleLocation}
              >
                <option value="" selected disabled>
                  {english
                    ? "Select your Preferred Location"
                    : "選擇您喜歡的工作地點"}
                </option>
                <option value="離島區">{english ? "Islands" : "離島區"}</option>
                <option value="葵青區">
                  {" "}
                  {english ? "Kwai Tsing" : "葵青區"}
                </option>
                <option value="北區">{english ? "North" : "北區"}</option>
                <option value="西貢區">
                  {english ? "Sai Kung" : "西貢區"}
                </option>
                <option value="沙田區">
                  {english ? "Sha Tin" : "沙田區"}{" "}
                </option>
                <option value="大埔區">{english ? "Tai Po" : "大埔區"}</option>
                <option value="荃灣區">
                  {" "}
                  {english ? "Tsuen Wan" : "荃灣區"}
                </option>
                <option value="屯門區">
                  {english ? "Tuen Mun" : "屯門區"}
                </option>
                <option value="元朗區">
                  {" "}
                  {english ? "Yuen Long" : "元朗區"}
                </option>
                <option value="九龍城區">
                  {english ? "	Kowloon City" : "九龍城區"}
                </option>
                <option value="觀塘區">
                  {english ? "Kwun Tong" : "觀塘區"}
                </option>
                <option value="深水埗區">
                  {english ? "Sham Shui Po" : "深水埗區"}
                </option>
                <option value="黃大仙區">
                  {english ? "Wong Tai Sin" : "黃大仙區"}
                </option>
                <option value="油尖旺區">
                  {english ? "Yau Tsim Mong" : "油尖旺區"}
                </option>
                <option value="中西區">
                  {english ? "Central and Western" : "中西區"}
                </option>
                <option value="東區">{english ? "Eastern" : "東區"}</option>
                <option value="南區">{english ? "Southern" : "南區"}</option>
                <option value="灣仔區">
                  {english ? "Wan Chai" : "灣仔區"}
                </option>
              </select>
            </div>
            <div className="row-item">
              {english ? "Language" : "語言 (可選擇多過一個）"}
              {languages.map((item) => {
                // Find the index of the item in totalRowData
                const index = languagesChinese.indexOf(item);
                // Get the English translation using the index
                const displayItem =
                  english && index !== -1 ? languagesEnglish[index] : item;

                return (
                  <div className="industry-container flex">
                    <div className="industry">{displayItem}</div>
                    <div className="remove">
                      {" "}
                      <label htmlFor={item} onClick={removeLanguages}>
                        <input type="checkbox" id={item} value={item} />
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </label>
                    </div>
                  </div>
                );
              })}
              <select
                name="preferred-languages"
                id="preferredLanguages"
                onChange={handleLanguages2}
              >
                <option value="" selected disabled>
                  {english ? "Language" : "選擇你識的語言"}
                </option>
                <option value="廣東話">
                  {english ? "Cantonese" : "廣東話"}
                </option>
                <option value="英文">{english ? "English" : "英文"}</option>
                <option value="普通話">
                  {english ? "Mandarin" : "普通話"}
                </option>
              </select>
            </div>
            <div className="row-item">
              <label htmlFor="">{english ? "Phone Number" : "電話號碼"}</label>
              <input type="text" onChange={handlePhone} />
            </div>

            <div className="row-item">
              <div className="sub-title">
                {english
                  ? "Choose 5 Best Descriptor"
                  : "選擇5個最能代表您的形容詞"}
              </div>
              <div className="bestfive-container">
                {bestFive.map((item) => {
                  // Find the index of the item in totalRowData
                  const index = totalRowData.indexOf(item);
                  // Get the English translation using the index
                  const displayItem =
                    english && index !== -1 ? totalRowDataEng[index] : item;

                  return (
                    <div key={item} className="descriptor-item">
                      {displayItem}
                    </div>
                  );
                })}
              </div>
              <button className="descriptor-button" onClick={toggleModal}>
                {english ? "Add / Edit +" : "新增 / 更改 + "}
              </button>
            </div>
            <div className="row-item">
              <div className="sub-title">
                {english ? "Past Experience" : "工作經驗 (可加多過一個）"}
              </div>
              {pastJob.map((item) => (
                <div className="industry-container flex">
                  <div className="industry">
                    {" "}
                    {item.jobTitle} | {item.startDate} | {item.endDate}
                  </div>
                  <div className="remove">
                    {" "}
                    <label htmlFor={item.jobTitle} onClick={removePastJobs}>
                      <input
                        type="checkbox"
                        id={item.jobTitle}
                        value={item.jobTitle}
                      />
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </label>
                  </div>
                </div>
              ))}

              <button className="add-more" onClick={toggleModal2}>
                Add More...
              </button>
            </div>
            <div className="row-item">
              <label htmlFor="">
                {english
                  ? "Preferred Industry"
                  : "偏好工作類別 (可選擇多過一個）"}
              </label>
              {preferredIndustry.map((item) => {
                // Find the index of the item in totalRowData
                const index = jobCategoryChi.indexOf(item);
                // Get the English translation using the index
                const displayItem =
                  english && index !== -1 ? jobCategoryEng[index] : item;

                return (
                  <div className="industry-container flex">
                    <div className="industry">{displayItem}</div>
                    <div className="remove">
                      {" "}
                      <label htmlFor={item} onClick={handleRemoveIndustry}>
                        <input type="checkbox" id={item} value={item} />
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </label>
                    </div>
                  </div>
                );
              })}
              <select name="" id="industry" onChange={handleIndustry}>
                <option value="" selected disabled>
                  {english ? "Choose Your Industry" : "選擇行業"}
                </option>
                <option value="清潔">{english ? "Cleaning" : "清潔"}</option>
                <option value="物流及運輸">
                  {english ? "Logistics" : "物流及運輸"}
                </option>
                <option value="餐飲">
                  {english ? "Food & Beverage" : "餐飲"}
                </option>
                <option value="零售">{english ? "Retail" : "零售"}</option>
              </select>
              <button
                className="create-account-btn"
                onMouseEnter={handleSubmit}
                onClick={handleSubmitFinal}
              >
                {english ? "Done" : "完成"}
              </button>
            </div>
            {/* Hidden Profile Pic for demo */}
            {/* <div className="row-item">
              <label htmlFor="File">
                {english ? "Profile Pic" : "Profile Pic"}
              </label>
              <input type="file" onChange={handleFile} />
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpEmployee;
