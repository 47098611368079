import React, { useState, useEffect, useContext, useRef } from "react";
import { Context } from "../App.jsx";
import { useNavigate } from "react-router-dom";
// import { CHINESE } from '../data/data.js'
// import { ENGLISH } from '../data/data.js'
import { WORKER } from "../data/data.jsx";
import { EMPLOYER } from "../data/data.jsx";
import platformTemplate from "../assets/platform-template.png";
import imageFlexibleJobs from "../assets/time.svg";
import imageWorkshops from "../assets/location.svg";
import imageTraining from "../assets/training.svg";
import videoRegister from "../assets/registerPage.png";
import Logo from ".././assets/logo.png";

export default function LanguagesButton({ children }) {
  const navigate = useNavigate();
  // const fakeData = [<div>hello <span class="red">red</span> bye</div>, ]
  const [selectedLanguage, setSelectedLanguage] = useState("chinese");
  const [selectedType, setSelectedType] = useState("worker");
  const [active, setActive] = useState(false);
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const toggleActive = () => {
    setActive(!active);
    console.log("active?", active);
  };

  const btnTypeRef = useRef(null);
  const btnLangRef = useRef(null);
  const btnTypeRefDesktop = useRef(null);
  const btnLangRefDesktop = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setEmail(""); // Clear the input field after submission
  };

  function switchToChinese() {
    setSelectedLanguage("chinese");
    console.log("chinese");
    btnLangRef.current.style.left = "0px";
    btnLangRefDesktop.current.style.left = "0px";
  }

  function switchToEnglish() {
    setSelectedLanguage("english");
    console.log("english");
    btnLangRef.current.style.left = "50px";
    btnLangRefDesktop.current.style.left = "50px";
  }

  function switchToWorker() {
    console.log("worker");
    setSelectedType("worker");
    btnTypeRef.current.style.left = "0px";
    btnTypeRefDesktop.current.style.left = "0px";
  }

  function switchToEmployer() {
    console.log("employer");
    setSelectedType("employer");
    btnTypeRef.current.style.left = "100px";
    btnTypeRefDesktop.current.style.left = "100px";
  }

  // function dataReturn() {
  //     if (selectedLanguage === "english") {
  //         return (ENGLISH)
  //     } else if (selectedLanguage ===  "chinese") {
  //         return (CHINESE)
  //     }
  // }

  function dataReturn() {
    if (selectedType === "worker") {
      return WORKER;
    } else if (selectedType === "employer") {
      return EMPLOYER;
    }
  }

  if (selectedType === "worker") {
    return (
      <>
        <div className="navbar">
          <div className="flex-container-banner">
            <img src={Logo} className="logo"></img>
            <div className="flex-container-banner-1">
              <div className="desktop-menu">
                <h1 className="word-banner">About Us</h1>
                <h1 className="word-banner">Contact Us</h1>
                <div className="button-border-box">
                  <div className="button-box-language">
                    <div id="btnLang" ref={btnLangRefDesktop}></div>
                    <button
                      type="button"
                      className="toggle-btn-language"
                      onClick={switchToChinese}
                    >
                      中
                    </button>
                    <button
                      type="button"
                      className="toggle-btn-language"
                      onClick={switchToEnglish}
                    >
                      Eng
                    </button>
                  </div>

                  <div className="button-box-type">
                    <div id="btnType" ref={btnTypeRefDesktop}></div>
                    <button
                      type="button"
                      className="toggle-btn-type"
                      isselected={selectedType === "worker"}
                      onClick={switchToWorker}
                    >
                      {dataReturn()[selectedLanguage].typeWorker}
                    </button>
                    <button
                      type="button"
                      className="toggle-btn-type"
                      isselected={selectedType === "employer"}
                      onClick={switchToEmployer}
                    >
                      {dataReturn()[selectedLanguage].typeEmployer}
                    </button>
                  </div>
                </div>
              </div>
              <div className="ham-menu" onClick={toggleActive}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="mobile-button-border-box">
                <div className="button-box-language">
                  <div id="btnLang" ref={btnLangRef}></div>
                  <button
                    type="button"
                    className="toggle-btn-language"
                    onClick={switchToChinese}
                  >
                    中
                  </button>
                  <button
                    type="button"
                    className="toggle-btn-language"
                    onClick={switchToEnglish}
                  >
                    Eng
                  </button>
                </div>

                <div className="button-box-type">
                  <div id="btnType" ref={btnTypeRef}></div>
                  <button
                    type="button"
                    className="toggle-btn-type"
                    isselected={selectedType === "worker"}
                    onClick={switchToWorker}
                  >
                    {dataReturn()[selectedLanguage].typeWorker}
                  </button>
                  <button
                    type="button"
                    className="toggle-btn-type"
                    isselected={selectedType === "employer"}
                    onClick={switchToEmployer}
                  >
                    {dataReturn()[selectedLanguage].typeEmployer}
                  </button>
                </div>
              </div>
              <div
                className={
                  active
                    ? "active off-screen-toggle"
                    : "hidden off-screen-toggle"
                }
              >
                <div
                  className={
                    active ? "active off-screen-menu" : "hidden off-screen-menu"
                  }
                >
                  <div className="cross" onClick={toggleActive}>
                    <span className="cross-1"></span>
                    <span className="cross-2"></span>
                  </div>
                  <div className="about-us">About Us</div>
                  <div className="contact-us">Contact Us</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="body">
          <div className="flex-container-main">
            <div className="content-word-box">
              <h1 className="main-header">
                {dataReturn()[selectedLanguage].mainHeader}
              </h1>
              <p className="main-body">
                {dataReturn()[selectedLanguage].mainBody}
              </p>
              <button
                className="Log-in-sign-up"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                {dataReturn()[selectedLanguage].loginSignup}
              </button>
            </div>
            <img src={platformTemplate} className="platformTemplate"></img>
          </div>
          <div className="main-2">
            <div className="flex-container-words">
              <h1 className="main-header-2">
                {dataReturn()[selectedLanguage].mainHeader2A}
                <span className="highlight">
                  {dataReturn()[selectedLanguage].mainHeader2B}
                </span>
                {dataReturn()[selectedLanguage].mainHeader2C}
              </h1>
              <h1 className="main-header-2-highlight"></h1>
              <h1 className="main-header-2"></h1>
            </div>
            <div className="flex-container-main-2">
              <div className="main-2-content">
                <h2>{dataReturn()[selectedLanguage].mainContent1A}</h2>
                <img
                  src={imageFlexibleJobs}
                  className="image-flexible-job"
                ></img>
                <p className="main-content-1A">
                  {dataReturn()[selectedLanguage].mainContent1AContent}
                </p>
              </div>
              <div className="main-2-content">
                <h2>{dataReturn()[selectedLanguage].mainContent1B}</h2>
                <img src={imageWorkshops} className="main-2-image"></img>
                <p>{dataReturn()[selectedLanguage].mainContent1BContent}</p>
              </div>
              <div className="main-2-content">
                <h2>{dataReturn()[selectedLanguage].mainContent1C}</h2>
                <img src={imageTraining} className="main-2-image"></img>
                <p>{dataReturn()[selectedLanguage].mainContent1CContent}</p>
              </div>
            </div>
          </div>
          <div className="main-3">
            <div className="flex-container-words">
              <h1 className="main-header-3">
                {dataReturn()[selectedLanguage].mainHeader3A}
                <span className="highlight">
                  {dataReturn()[selectedLanguage].mainHeader3B}
                </span>
                {dataReturn()[selectedLanguage].mainHeader3C}
              </h1>
            </div>
            <div className="flex-container-main-2">
              <div className="main-3-content">
                <h1 className="main-content-3-image">
                  {dataReturn()[selectedLanguage].mainContent3AImage}
                </h1>
                <p className="main-content-3-content">
                  {dataReturn()[selectedLanguage].mainContent2AContent}
                </p>
              </div>
              <div className="main-3-content">
                <h1 className="main-content-3-image">
                  {dataReturn()[selectedLanguage].mainContent3BImage}
                </h1>
                <p className="main-content-3-content">
                  {dataReturn()[selectedLanguage].mainContent2BContent}
                </p>
              </div>
              <div className="main-3-content">
                <h1 className="main-content-3-image">
                  {dataReturn()[selectedLanguage].mainContent3CImage}
                </h1>
                <p className="main-content-3-content">
                  {dataReturn()[selectedLanguage].mainContent2CContent}
                </p>
              </div>
              <div className="main-3-content">
                <h1 className="main-content-3-image">
                  {dataReturn()[selectedLanguage].mainContent3DImage}
                </h1>
                <p className="main-content-3-content">
                  {dataReturn()[selectedLanguage].mainContent2DContent}
                </p>
              </div>
            </div>
          </div>
          <div className="main-4">
            <div className="grid-container">
              <img src={imageTraining} className="main-4-image"></img>
              <div>
                <div className="flex-container-words-content-4">
                  <h1 className="main-header-4">
                    {dataReturn()[selectedLanguage].mainHeader4A}
                    <span className="highlight">
                      {dataReturn()[selectedLanguage].mainHeader4B}
                    </span>
                    {dataReturn()[selectedLanguage].mainHeader4C}
                  </h1>
                </div>
                <div className="content-4">
                  <div className="main-4-content">
                    <h1 className="main-4-content-header-left">
                      {dataReturn()[selectedLanguage].mainContent4AHeader}
                    </h1>
                    <p className="main-4-content-content-left">
                      {dataReturn()[selectedLanguage].mainContent4AContent}
                    </p>
                  </div>
                  <div className="main-4-content">
                    <h1 className="main-4-content-header-right">
                      {dataReturn()[selectedLanguage].mainContent4BHeader}
                    </h1>
                    <p className="main-4-content-content-right">
                      {dataReturn()[selectedLanguage].mainContent4BContent}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="main-5"
            style={{
              backgroundColor: "white",
              padding: "20px 20px 40px 20px",
              textAlign: "center",
            }}
          >
            <h2>{dataReturn()[selectedLanguage].main5A}</h2>
            <p>{dataReturn()[selectedLanguage].main5B}</p>
            {!submitted ? (
              <form onSubmit={handleSubmit} style={{ display: "inline-block" }}>
                <input
                  type="email"
                  placeholder={dataReturn()[selectedLanguage].main5C}
                  value={email} // Controlled input
                  onChange={(e) => setEmail(e.target.value)} // Update state on input change
                  required
                  style={{
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    width: "250px",
                  }}
                />
                <button
                  type="submit"
                  style={{
                    padding: "10px 15px",
                    backgroundColor: "pink",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  {dataReturn()[selectedLanguage].main5D}
                </button>
              </form>
            ) : (
              <p style={{ color: "green", marginTop: "10px" }}>
                {dataReturn()[selectedLanguage].main5E}
              </p>
            )}
          </div>
        </div>
      </>
    );
  } else if (selectedType === "employer") {
    return (
      <>
        <div className="navbar">
          <div className="flex-container-banner">
            <img src={Logo} className="logo"></img>
            <div className="flex-container-banner-1">
              <div className="desktop-menu">
                <h1 className="word-banner">About Us</h1>
                <h1 className="word-banner">Contact Us</h1>
                <div className="button-border-box">
                  <div className="button-box-language">
                    <div id="btnLang" ref={btnLangRefDesktop}></div>
                    <button
                      type="button"
                      className="toggle-btn-language"
                      onClick={switchToChinese}
                    >
                      中
                    </button>
                    <button
                      type="button"
                      className="toggle-btn-language"
                      onClick={switchToEnglish}
                    >
                      Eng
                    </button>
                  </div>

                  <div className="button-box-type">
                    <div id="btnType" ref={btnTypeRefDesktop}></div>
                    <button
                      type="button"
                      className="toggle-btn-type"
                      onClick={switchToWorker}
                    >
                      {dataReturn()[selectedLanguage].typeWorker}
                    </button>
                    <button
                      type="button"
                      className="toggle-btn-type"
                      onClick={switchToEmployer}
                    >
                      {dataReturn()[selectedLanguage].typeEmployer}
                    </button>
                  </div>
                </div>
              </div>

              <div className="ham-menu" onClick={toggleActive}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="mobile-button-border-box">
                <div className="button-box-language">
                  <div id="btnLang" ref={btnLangRef}></div>
                  <button
                    type="button"
                    className="toggle-btn-language"
                    onClick={switchToChinese}
                  >
                    中
                  </button>
                  <button
                    type="button"
                    className="toggle-btn-language"
                    onClick={switchToEnglish}
                  >
                    Eng
                  </button>
                </div>

                <div className="button-box-type">
                  <div id="btnType" ref={btnTypeRef}></div>
                  <button
                    type="button"
                    className="toggle-btn-type"
                    isselected={selectedType === "worker"}
                    onClick={switchToWorker}
                  >
                    {dataReturn()[selectedLanguage].typeWorker}
                  </button>
                  <button
                    type="button"
                    className="toggle-btn-type"
                    isselected={selectedType === "employer"}
                    onClick={switchToEmployer}
                  >
                    {dataReturn()[selectedLanguage].typeEmployer}
                  </button>
                </div>
              </div>
              <div
                className={
                  active
                    ? "active off-screen-toggle"
                    : "hidden off-screen-toggle"
                }
                onClick={toggleActive}
              >
                <div
                  className={
                    active ? "active off-screen-menu" : "hidden off-screen-menu"
                  }
                >
                  <div className="cross">
                    <span className="cross-1"></span>
                    <span className="cross-2"></span>
                  </div>
                  <div className="about-us">About Us</div>
                  <div className="contact-us">Contact Us</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="body">
          <div className="flex-container-main">
            <div className="content-word-box">
              <h1 className="main-header">
                {dataReturn()[selectedLanguage].mainHeader}
              </h1>
              {/* <h1 className='main-header'>{dataReturn()[selectedType].mainHeader1}</h1>
                        <h1 className='main-header'>{dataReturn()[selectedType].mainHeader2}</h1>
                        <h1 className='main-header'>{dataReturn()[selectedType].mainHeader3}</h1>
                        <h1 className='main-header'>{dataReturn()[selectedType].mainHeader4}</h1>
                        <h1 className='main-header'>{dataReturn()[selectedType].mainHeader5}</h1>
                        <h1 className='main-header'>{dataReturn()[selectedType].mainHeader6}</h1> */}
              <p className="main-body">
                {dataReturn()[selectedLanguage].mainBody}
              </p>
              <button
                className="Log-in-sign-up"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                {dataReturn()[selectedLanguage].loginSignup}
              </button>
            </div>
            <img src={platformTemplate} className="platformTemplate"></img>
          </div>
          <div className="main-2-employer">
            <div className="flex-container-words">
              <h1 className="main-header-2-employer">
                {dataReturn()[selectedLanguage].mainHeader2A}
              </h1>
              <h1 className="main-header-2-highlight-employer">
                {dataReturn()[selectedLanguage].mainHeader2B}
              </h1>
              <h1 className="main-header-2-employer">
                {dataReturn()[selectedLanguage].mainHeader2C}
              </h1>
            </div>
          </div>
          <div className="flex-container-main">
            <div className="content-word-box">
              <h1 className="main-header">
                {dataReturn()[selectedLanguage].mainHeader3}
              </h1>
              <p className="main-body">
                {dataReturn()[selectedLanguage].mainContent3}
              </p>
            </div>
            <img src={videoRegister} className="platformTemplate"></img>
          </div>
          <div className="flex-container-main reverse-column">
            <img src={platformTemplate} className="platformTemplate"></img>
            <div className="content-word-box">
              <h1 className="main-header">
                {dataReturn()[selectedLanguage].mainHeader4}
              </h1>
              <p className="main-body">
                {dataReturn()[selectedLanguage].mainContent4}
              </p>
            </div>
          </div>
          <div className="flex-container-main">
            <div className="content-word-box">
              <h1 className="main-header">
                {dataReturn()[selectedLanguage].mainHeader5}
              </h1>
              <p className="main-body">
                {dataReturn()[selectedLanguage].mainContent5}
              </p>
            </div>
            <img src={platformTemplate} className="platformTemplate"></img>
          </div>
        </div>
      </>
    );
  }
}
