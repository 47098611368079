import React from "react";

function StarsDisplay(props) {
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={`star ${i <= props.rating ? "filled" : ""}`}>
          ★
        </span>
      );
    }
    return stars;
  };

  return <div className="StarsDisplay">{renderStars()}</div>;
}

export default StarsDisplay;
