import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import DescriptorComponent from "./DescriptorComponent.jsx";
import axios from "axios";
import { Context } from "./App.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faXmark } from "@fortawesome/free-solid-svg-icons";

import LanguageComponent from "./components/LanguageComponent.jsx";
import NavbarEmployer from "./components/NavbarEmployer.jsx";

function CreateJobEmployer() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [files, setFiles] = useState([]);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState(null);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [preferredIndustry, setPreferredIndustry] = useState([]);
  const jobCategoryEng = ["Cleaning", "Logistics", "Food & Beverage", "Retail"];
  const jobCategoryChi = ["清潔", "物流及運輸", "餐飲", "零售"];
  const [category, setCategory] = useState("");
  const [finalData, setFinalData] = useState({
    benefits: "",
    // companyName: "",
    category: "",
    date: "",
    description: "",
    district: "",
    endTime: "",
    jobTitle: "",
    location: "",
    pay: "",
    requirement: "",
    startTime: "",
    tags: "",
    vacancy: "",
  });

  const navigate = useNavigate();
  // Below is data from previous screen, their login details
  const location = useLocation();
  const { state } = location;

  // Configs below for axiox;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const multiFormConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  const handleRemoveIndustry = (e) => {
    setPreferredIndustry([
      ...preferredIndustry.filter((item) => item != e.target.value),
    ]);
    setFinalData((prev) => {
      return { ...prev, preferredIndustry: [...preferredIndustry] };
    });
  };

  const tagRef = useRef(null);

  const fakeData = {
    companyName: "hi",
    legalBusinessName: "legal",
    contactName: "Anson",
    contactNumber: 90957521,
    address: "Vista",
    logo: "fakestringhere",
    // BR: "blackfolder.jpg",
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  const handleRemoveLocation = (e) => {
    setPreferredLocation([
      ...preferredLocation.filter((item) => item != e.target.value),
    ]);
  };

  // This is job category
  const handleIndustry = (e) => {
    setCategory(e.target.value);
    setFinalData((prev) => {
      return { ...prev, category: e.target.value };
    });
  };

  const handleDistrict = (e) => {
    console.log(e.target.value);
    setPreferredLocation([e.target.value]);
    setFinalData((prev) => {
      return { ...prev, district: e.target.value };
    });
  };

  const handleFileChange = (e) => {
    setFiles([...files, ...e.target.files]);
  };

  useEffect(() => {
    console.log(files);
  }, [files]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Activated Mouse Hover");
    console.log("This is the final Data from hover :", finalData);
  };

  const handleSubmitFinal = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://flexapp-0c3a60ff7bd0.herokuapp.com/job",
        finalData,
        // fakeData,
        config
      )
      .then((res) => {
        console.log(finalData);
        console.log("POSTED JOB SUCCEDED");
        navigate("/home/employer");
      })
      .catch((err) => {
        console.log(finalData);
        console.log(config, "Failed");
        alert(
          "Sign Up Failed, Please Check All the Fields are Inputted Correctly"
        );
      });
    console.log(finalData);
    // console.log(fakeData)
  };
  // const handleCompanyName = (e) => {
  //   setFinalData((prev) => {
  //     return { ...prev, companyName: e.target.value };
  //   });
  // };

  const handleBenefits = (e) => {
    setFinalData((prev) => {
      return { ...prev, benefits: e.target.value };
    });
  };
  const handleAddress = (e) => {
    setFinalData((prev) => {
      return { ...prev, location: e.target.value };
    });
  };

  const handleDate = (e) => {
    setFinalData((prev) => {
      return { ...prev, date: e.target.value };
    });
  };

  const handleDescription = (e) => {
    setFinalData((prev) => {
      return { ...prev, description: e.target.value };
    });
  };

  const handleEndTime = (e) => {
    setFinalData((prev) => {
      return { ...prev, endTime: e.target.value };
    });
  };

  const handleJobTitle = (e) => {
    setFinalData((prev) => {
      return { ...prev, jobTitle: e.target.value };
    });
  };

  const handlePay = (e) => {
    setFinalData((prev) => {
      return { ...prev, pay: Number(e.target.value) };
    });
  };
  const handleRequirement = (e) => {
    setFinalData((prev) => {
      return { ...prev, requirement: e.target.value };
    });
  };
  const handleStartTime = (e) => {
    setFinalData((prev) => {
      return { ...prev, startTime: e.target.value };
    });
  };

  const handleVacancy = (e) => {
    setFinalData((prev) => {
      return { ...prev, vacancy: Number(e.target.value) };
    });
  };

  const handleTag = (e) => {
    setTag(e.target.value);
  };
  const clearInputValue = () => {
    setTag(null);
    tagRef.current.value = "";
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };
  const addTag = (e) => {
    e.preventDefault();
    setTags([...tags, tag]);
    clearInputValue();
  };

  useEffect(() => {
    setFinalData((prev) => {
      return { ...prev, tags: tags };
    });
  }, [tags]);

  return (
    <div>
      <LanguageComponent />
      <NavbarEmployer />
      <div className="create-profile-container">
        <div className="title header-2">
          {english ? "Create a Job Listing" : "新增職缺"}
        </div>
        <form action="">
          <div className="grid-container">
            <div className="row-item">
              <label htmlFor="job-title">
                {english ? "Job Listing Title" : "職位名稱"}
              </label>
              <input type="text" onChange={handleJobTitle} name="job-title" />
            </div>
            <div className="row-item">
              <label htmlFor="job-description">
                {english ? "Job Description" : "工作詳情"}
              </label>
              <input
                type="text"
                onChange={handleDescription}
                name="job-description"
              />
            </div>
            <div className="row-item">
              <label htmlFor="address">{english ? "Date" : "日期"}</label>
              <input type="date" onChange={handleDate} name="date" />
            </div>
            <div className="row-item">
              <label htmlFor="">{english ? "Job Category" : "工種"}</label>
              {preferredIndustry.map((item) => {
                // Find the index of the item in totalRowData
                const index = jobCategoryChi.indexOf(item);
                // Get the English translation using the index
                const displayItem =
                  english && index !== -1 ? jobCategoryEng[index] : item;

                return (
                  <div className="industry-container flex">
                    <div className="industry">{displayItem}</div>
                    <div className="remove">
                      {" "}
                      <label htmlFor={item} onClick={handleRemoveIndustry}>
                        <input type="checkbox" id={item} value={item} />
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </label>
                    </div>
                  </div>
                );
              })}
              <select
                name=""
                id="industry"
                onChange={handleIndustry}
                value={category}
              >
                <option value="" selected disabled>
                  {english ? "Choose Your Industry" : "選擇行業"}
                </option>
                <option value="清潔">{english ? "Cleaning" : "清潔"}</option>
                <option value="物流及運輸">
                  {english ? "Logistics" : "物流及運輸"}
                </option>
                <option value="餐飲">
                  {english ? "Food & Beverage" : "餐飲"}
                </option>
                <option value="零售">{english ? "Retail" : "零售"}</option>
              </select>
            </div>
            <div className="row-item">
              <label htmlFor="start-time">
                {english ? "Start Time" : "開工時間"}
              </label>
              <input type="time" onChange={handleStartTime} name="start-time" />
            </div>
            <div className="row-item">
              <label htmlFor="end-time">
                {english ? "End Time" : "完工時間"}
              </label>
              <input type="time" onChange={handleEndTime} name="end-time" />
            </div>
            <div className="row-item">
              <label htmlFor="pay">{english ? "Pay" : "薪資"}</label>
              <input type="number" onChange={handlePay} name="pay" />
            </div>
            <div className="row-item">
              <label htmlFor="requirement">
                {english ? "Requirement" : "要求"}
              </label>
              <input
                type="text"
                onChange={handleRequirement}
                name="requirement"
              />
            </div>
            <div className="row-item">
              <label htmlFor="benefit">
                {english ? "Benefit(s)" : "員工福利"}
              </label>
              <input type="text" onChange={handleBenefits} name="benefit" />
            </div>
            <div className="row-item">
              <label htmlFor="district">
                {english ? "Job District" : "地區"}
              </label>
              <select
                name="district"
                id="district"
                onChange={handleDistrict}
                defaultValue="default"
              >
                <option value="default" disabled>
                  {english ? "Select the work district" : "選擇工作地區"}
                </option>
                <option value="離島區">{english ? "Islands" : "離島區"}</option>
                <option value="葵青區">
                  {" "}
                  {english ? "Kwai Tsing" : "葵青區"}
                </option>
                <option value="北區">{english ? "North" : "北區"}</option>
                <option value="西貢區">
                  {english ? "Sai Kung" : "西貢區"}
                </option>
                <option value="沙田區">
                  {english ? "Sha Tin" : "沙田區"}{" "}
                </option>
                <option value="大埔區">{english ? "Tai Po" : "大埔區"}</option>
                <option value="荃灣區">
                  {" "}
                  {english ? "Tsuen Wan" : "荃灣區"}
                </option>
                <option value="屯門區">
                  {english ? "Tuen Mun" : "屯門區"}
                </option>
                <option value="元朗區">
                  {" "}
                  {english ? "Yuen Long" : "元朗區"}
                </option>
                <option value="九龍城區">
                  {english ? "	Kowloon City" : "九龍城區"}
                </option>
                <option value="觀塘區">
                  {english ? "Kwun Tong" : "觀塘區"}
                </option>
                <option value="深水埗區">
                  {english ? "Sham Shui Po" : "深水埗區"}
                </option>
                <option value="黃大仙區">
                  {english ? "Wong Tai Sin" : "黃大仙區"}
                </option>
                <option value="油尖旺區">
                  {english ? "Yau Tsim Mong" : "油尖旺區"}
                </option>
                <option value="中西區">
                  {english ? "Central and Western" : "中西區"}
                </option>
                <option value="東區">{english ? "Eastern" : "東區"}</option>
                <option value="南區">{english ? "Southern" : "南區"}</option>
                <option value="灣仔區">
                  {english ? "Wan Chai" : "灣仔區"}
                </option>
              </select>
            </div>
            <div className="row-item">
              <label htmlFor="location">{english ? "Location" : "地址"}</label>
              <input type="text" onChange={handleAddress} name="location" />
            </div>
            <div className="row-item">
              <label htmlFor="tags">{english ? "Tags" : "標籤"}</label>
              <div className="input-container">
                <input
                  type="text"
                  onChange={handleTag}
                  name="tags"
                  ref={tagRef}
                />
                <button className="add-tag" onClick={addTag}>
                  {english ? "Add Tag" : "加標籤"}
                </button>
                <div className="tag-container">
                  {tags &&
                    tags.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="tag-item"
                          onClick={() => handleRemoveTag(index)}
                        >
                          {item}
                          <FontAwesomeIcon icon={faXmark} />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="row-item">
              <label htmlFor="vacancy">
                {english ? "Vacancy" : "職位空缺"}
              </label>
              <input type="number" onChange={handleVacancy} name="vacancy" />
            </div>
            <div className="row-item">
              <label htmlFor="dress-code">
                {english ? "Dress Code" : "著裝要求"}
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                multiple
                name="dress-code"
              />
              <div className="file-items">
                {files &&
                  files.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="file-item"
                        onClick={() => removeFile(index)}
                      >
                        <div className="left">{item.name}</div>
                        <div className="right">
                          {" "}
                          <FontAwesomeIcon icon={faXmark} />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <button
            className="create-account-btn"
            onMouseEnter={handleSubmit}
            onClick={handleSubmitFinal}
          >
            {english ? "Done" : "完成"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateJobEmployer;
