import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Context } from "./App.jsx";
import LanguageComponent from "./components/LanguageComponent.jsx";
import axios from "axios";

// Language selection overlay component
const LanguageOverlay = ({
  languages,
  selectedLanguages,
  toggleLanguage,
  closeOverlay,
}) => (
  <div className="language-overlay">
    <div className="overlay-content">
      <h3>選擇語言</h3>
      {languages.map((language) => (
        <div key={language} className="language-option">
          <label htmlFor={language}>
            {" "}
            {language}
            <input
              type="checkbox"
              id={language}
              checked={selectedLanguages.includes(language)}
              onChange={() => toggleLanguage(language)}
            />
            <span class="custom-checkbox"></span>
          </label>
        </div>
      ))}
      <button onClick={closeOverlay} className="button">
        關閉
      </button>
    </div>
  </div>
);

const AddOptionOverlay = ({
  options,
  selectedOptions,
  toggleOption,
  closeOverlay,
}) => (
  <div className="option-overlay">
    <div className="overlay-content">
      <h3>選擇形容詞</h3>
      {options.map((option) => (
        <div key={option} className="option-item">
          <label htmlFor={option}>
            {option}
            <input
              type="checkbox"
              id={option}
              checked={selectedOptions.includes(option)}
              onChange={() => toggleOption(option)}
            />
            <span class="custom-checkbox"></span>
          </label>
        </div>
      ))}
      <button onClick={closeOverlay} className="button">
        關閉
      </button>
    </div>
  </div>
);

const Step1 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  currentStep,
  english,
}) => {
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const languages = ["英文", "廣東話", "普通話"];

  const toggleOverlay = () => setOverlayVisible(!isOverlayVisible);

  const toggleLanguage = (language) => {
    setFormData((prevData) => {
      const updatedLanguages = prevData.languages.includes(language)
        ? prevData.languages.filter((lang) => lang !== language)
        : [...prevData.languages, language];
      return { ...prevData, languages: updatedLanguages };
    });
  };

  return (
    <div
      className={`${
        isOverlayVisible ? "step-component blur" : "step-component"
      }`}
    >
      <div class="dark-overlay"></div>
      <div className={`${isOverlayVisible ? "blur" : ""}`}>
        <div className="title">
          {english ? "Give us your basic information" : "提供您的基本資料"}
        </div>
        <div className="input-field-item">
          <input
            type="text"
            placeholder={english ? "First Name" : "名"}
            value={formData.firstName}
            onChange={(e) =>
              setFormData({ ...formData, firstName: e.target.value })
            }
          />
        </div>
        <div className="input-field-item">
          <input
            type="text"
            placeholder={english ? "Last Name" : "姓名"}
            value={formData.lastName}
            onChange={(e) =>
              setFormData({ ...formData, lastName: e.target.value })
            }
          />
        </div>
        <div className="input-field-item">
          <input
            type="text"
            placeholder={english ? "Mobile Number" : "電話號碼"}
            value={formData.number}
            onChange={(e) =>
              setFormData({ ...formData, number: e.target.value })
            }
          />
        </div>
        <div
          className="input-field-item language-toggle"
          onClick={toggleOverlay}
        >
          <div className="text">
            {english ? "Languages: " : "語言: "}{" "}
            {formData.languages.length > 0
              ? formData.languages.join(", ")
              : english
              ? "Select Language"
              : "選擇語言"}
          </div>
        </div>
        <div className="bottom-container">
          <div className="button-container">
            <button onClick={prevStep}>上一頁</button>
            <button onClick={nextStep}>下一頁</button>
          </div>
          <StepIndicator currentStep={currentStep} totalSteps={4} />
        </div>
      </div>

      {isOverlayVisible && (
        <LanguageOverlay
          languages={languages}
          selectedLanguages={formData.languages}
          toggleLanguage={toggleLanguage}
          closeOverlay={toggleOverlay}
        />
      )}
    </div>
  );
};

const Step2 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  currentStep,
  english,
}) => {
  const [isPersonalityOverlayVisible, setPersonalityOverlayVisible] =
    useState(false);
  const [isTechnicalOverlayVisible, setTechnicalOverlayVisible] =
    useState(false);

  // Initialize state from formData
  const [selectedPersonalityOptions, setSelectedPersonalityOptions] = useState(
    formData.selectedPersonalityOptions || []
  );
  const [selectedTechnicalOptions, setSelectedTechnicalOptions] = useState(
    formData.selectedTechnicalOptions || []
  );

  const rowOneDataEng = [
    "Listening to Others",
    "Adaptability",
    "Empathy",
    "Responsibility",
    "Systems Thinking",
    "Communication and Collaboration",
    "Patience",
    "Time Management",
    "Open-mindedness",
    "Compassion",
  ];
  const rowOneData = [
    "聆聽他人",
    "適應變化",
    "換位思考",
    "責任心",
    "系統思考",
    "溝通協作",
    "耐性",
    "時間管理",
    "開放思",
    "有愛心",
  ];

  const rowTwoDataEng = [
    "Data Entry",
    "Writing",
    "Presentation Creation",
    "File Management",
    "Baking",
    "Cleaning",
    "Childcare",
    "Customer Service",
  ];
  const rowTwoData = [
    "數據錄入",
    "寫作",
    "簡報製作",
    "檔案管理",
    "烘焙",
    "清潔",
    "育兒",
    "客戶服務",
  ];

  // Toggle function for personality overlay
  const togglePersonalityOverlay = () => {
    setTechnicalOverlayVisible(false); // Close technical overlay if open
    setPersonalityOverlayVisible((prev) => !prev);
  };

  // Toggle function for technical overlay
  const toggleTechnicalOverlay = () => {
    setPersonalityOverlayVisible(false); // Close personality overlay if open
    setTechnicalOverlayVisible((prev) => !prev);
  };

  // Toggle option for personality
  const togglePersonalityOption = (option) => {
    const totalSelected =
      selectedPersonalityOptions.length + selectedTechnicalOptions.length;

    if (totalSelected >= 5 && !selectedPersonalityOptions.includes(option)) {
      alert("You can only select a total of 5 options.");
      return;
    }

    setSelectedPersonalityOptions((prevSelected) => {
      const newSelected = prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : [...prevSelected, option];

      // Update formData with selected options
      setFormData((prevData) => ({
        ...prevData,
        selectedPersonalityOptions: newSelected,
        descriptors: [...newSelected, ...selectedTechnicalOptions].slice(0, 5),
      }));

      return newSelected;
    });
  };

  // Toggle option for technical skills
  const toggleTechnicalOption = (option) => {
    const totalSelected =
      selectedPersonalityOptions.length + selectedTechnicalOptions.length;

    if (totalSelected >= 5 && !selectedTechnicalOptions.includes(option)) {
      alert("You can only select a total of 5 options.");
      return;
    }

    setSelectedTechnicalOptions((prevSelected) => {
      const newSelected = prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : [...prevSelected, option];

      // Update formData with selected options
      setFormData((prevData) => ({
        ...prevData,
        selectedTechnicalOptions: newSelected,
        descriptors: [...selectedPersonalityOptions, ...newSelected].slice(
          0,
          5
        ),
      }));

      return newSelected;
    });
  };

  // Determine if .blur should be applied
  const isBlurred = isPersonalityOverlayVisible || isTechnicalOverlayVisible;

  // Determine which options to display based on the language
  const personalityOptions = english ? rowOneDataEng : rowOneData;
  const technicalOptions = english ? rowTwoDataEng : rowTwoData;

  return (
    <div className={`step-component ${isBlurred ? "blur" : ""}`}>
      <div className="dark-overlay"></div>
      <div className={`${isBlurred ? "blur" : ""}`}>
        <div className="title">
          {english ? "Choose 5 Best Descriptor" : "選擇5個最能代表您的形容詞"}
        </div>

        <div className="item-1">
          <div className="sub-title">{english ? "Personality" : "性格"}</div>
          <div className="tags-display">
            {selectedPersonalityOptions.map((option) => (
              <div key={option} className="tag">
                {english ? rowOneDataEng[rowOneData.indexOf(option)] : option}
              </div>
            ))}
          </div>
          <button onClick={togglePersonalityOverlay}>加形容詞 +</button>
        </div>

        <div className="item-2">
          <div className="sub-title">
            {english ? "Technical Skills" : "技術技能"}
          </div>
          <div className="tags-display">
            {selectedTechnicalOptions.map((option) => (
              <div key={option} className="tag">
                {english ? rowTwoDataEng[rowTwoData.indexOf(option)] : option}
              </div>
            ))}
          </div>
          <button onClick={toggleTechnicalOverlay}>加形容詞 +</button>
        </div>

        <div className="bottom-container">
          <div className="button-container">
            <button onClick={prevStep}>上一頁</button>
            <button onClick={nextStep}>下一頁</button>
          </div>
          <StepIndicator currentStep={currentStep} totalSteps={4} />
        </div>
      </div>

      {isPersonalityOverlayVisible && (
        <AddOptionOverlay
          options={personalityOptions} // Use the determined options
          selectedOptions={selectedPersonalityOptions}
          toggleOption={togglePersonalityOption}
          closeOverlay={togglePersonalityOverlay}
        />
      )}
      {isTechnicalOverlayVisible && (
        <AddOptionOverlay
          options={technicalOptions} // Use the determined options
          selectedOptions={selectedTechnicalOptions}
          toggleOption={toggleTechnicalOption}
          closeOverlay={toggleTechnicalOverlay}
        />
      )}
    </div>
  );
};

const AddJobOverlay = ({
  newJob,
  setNewJob,
  addJob,
  closeOverlay,
  english,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewJob((prev) => ({ ...prev, [name]: value }));
  };

  const handleEmploymentTypeChange = (type) => {
    setNewJob((prev) => ({ ...prev, employmentType: type }));
  };

  const employmentTypes = ["全職", "兼職", "散工"];

  return (
    <div className="past-job-overlay">
      <div className="overlay-content">
        <h3>{english ? "Add New Job" : "加工作經驗"}</h3>
        <div>
          <label>{english ? "Employment Type: " : "就業類型: "}</label>
          <div className="employment-type-options">
            {employmentTypes.map((type) => (
              <div
                key={type}
                className={`employment-type ${
                  newJob.employmentType === type ? "selected" : ""
                }`}
                onClick={() => handleEmploymentTypeChange(type)}
              >
                {type}
              </div>
            ))}
          </div>
        </div>
        <div>
          <label>{english ? "Company Name: " : "公司名稱: "}</label>
          <input
            type="text"
            name="companyName"
            value={newJob.companyName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>{english ? "Job Position: " : "職位名: "}</label>
          <input
            type="text"
            name="jobPosition"
            value={newJob.jobPosition}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>{english ? "Start Date: " : "開始年份: "}</label>
          <input
            type="month"
            name="startDate"
            value={newJob.startDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>{english ? "End Date: " : "結束年份: "}</label>
          <input
            type="month"
            name="endDate"
            value={newJob.endDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>{english ? "Job Description: " : "工作詳情: "}</label>
          <textarea
            name="jobDescription"
            value={newJob.jobDescription}
            onChange={handleInputChange}
          />
        </div>
        <div className="button-container">
          <button onClick={closeOverlay} className="job-button">
            {english ? "Close" : "關閉"}
          </button>
          <button onClick={addJob} className="job-button">
            {english ? "Add Job" : "加工作經驗"}
          </button>
        </div>
      </div>
    </div>
  );
};

const Step3 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  currentStep,
  english,
}) => {
  const [newJob, setNewJob] = useState({
    employmentType: "",
    companyName: "",
    jobPosition: "",
    startDate: "",
    endDate: "",
    jobDescription: "",
  });
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const toggleOverlay = () => setOverlayVisible(!isOverlayVisible);

  const addJob = () => {
    const updatedJobs = [...formData.pastExperience, newJob];
    setFormData((prevData) => ({ ...prevData, pastExperience: updatedJobs }));
    setNewJob({
      employmentType: "",
      companyName: "",
      jobPosition: "",
      startDate: "",
      endDate: "",
      jobDescription: "",
    });
    setOverlayVisible(false); // Close the overlay after adding the job
  };

  return (
    <div
      className={`${
        isOverlayVisible ? "step-component blur" : "step-component"
      }`}
    >
      <div class="dark-overlay"></div>
      <div className={`${isOverlayVisible ? "blur" : ""}`}>
        <div className="title"> {english ? "Job Experiences" : "工作經歷"}</div>
        <button className="add-job-button" onClick={toggleOverlay}>
          加工作經驗 +
        </button>
        {formData.pastExperience.length > 0 ? (
          <div className="past-job-container">
            {formData.pastExperience.map((job, index) => (
              <div key={index} className="past-job-entry">
                <div className="row one">
                  <div>
                    <span>{job.companyName}</span>
                  </div>
                  <div className="employment-type"> {job.employmentType}</div>
                </div>
                <div className="row">
                  <div>
                    {job.jobPosition}: {job.startDate} - {job.endDate}
                  </div>
                </div>
                <div className="row">
                  <div>{job.jobDescription}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {english ? "No job experiences added yet." : "未加工作經驗"}
          </div>
        )}
      </div>
      {isOverlayVisible && (
        <AddJobOverlay
          newJob={newJob}
          setNewJob={setNewJob}
          addJob={addJob}
          closeOverlay={toggleOverlay}
          english={english}
        />
      )}
      <div className="bottom-container">
        <div className="button-container">
          <button onClick={prevStep}>上一頁</button>
          <button onClick={nextStep}>下一頁</button>
        </div>
        <StepIndicator currentStep={currentStep} totalSteps={4} />
      </div>
    </div>
  );
};

const Step4 = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  currentStep,
  english,
  apiUrl,
}) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    // Create a new object excluding selectedPersonalityOptions and selectedTechnicalOptions
    const {
      selectedPersonalityOptions,
      selectedTechnicalOptions,
      ...dataForSubmission
    } = formData;

    console.log("This is the data for submission: ", dataForSubmission);

    axios
      .post(`${apiUrl}/user/details`, dataForSubmission, config)
      .then((res) => {
        console.log(res);
        navigate("/employee/job");
        // You can handle successful response here if needed
      })
      .catch((err) => {
        console.log(err);
        // Show an alert with the error message
        alert(
          `Submission failed: ${err.response ? err.response.data : err.message}`
        );
      });
  };

  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: file,
      }));
    }
  };

  return (
    <div className="step-component">
      <div className="title">
        {english ? "Profile Pic & CV" : "個人照片和簡歷"}
      </div>
      <div className="item-1">
        <div className="sub-title">{english ? "Profile Pic" : "個人照片"}</div>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "profilePic")}
        />
      </div>
      <div className="item-2">
        <div className="sub-title">{english ? "CV" : "簡歷"}</div>
        <input
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={(e) => handleFileChange(e, "cv")}
        />
      </div>
      <div className="bottom-container">
        <div className="button-container">
          <button onClick={prevStep}>上一頁</button>
          <button onClick={handleSubmit}>確認</button>
        </div>
        <StepIndicator currentStep={currentStep} totalSteps={4} />
      </div>
    </div>
  );
};
const StepIndicator = ({ currentStep, totalSteps }) => {
  return (
    <div className="step-indicator">
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          className={`step-circle ${currentStep === index + 1 ? "active" : ""}`}
        >
          {index + 1}
        </div>
      ))}
    </div>
  );
};

function SignUpEmployeeNew() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    number: "",
    languages: [],
    descriptors: [],
    pastExperience: [],
    profilePic: null,
    cv: null,
    // Here onwards, testing data
    preferredDistrict: ["沙田區"],
    preferredIndustry: ["物流及運輸"],
  });

  const [currentStep, setCurrentStep] = useState(1);
  const nextStep = () => setCurrentStep((prev) => Math.min(prev + 1, 4));
  const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 1));
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);

  return (
    <div className="SignUpEmployeeNew">
      <LanguageComponent />
      {currentStep === 1 && (
        <Step1
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
          currentStep={currentStep}
          english={english}
        />
      )}
      {currentStep === 2 && (
        <Step2
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
          currentStep={currentStep}
          english={english}
        />
      )}
      {currentStep === 3 && (
        <Step3
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
          currentStep={currentStep}
          english={english}
        />
      )}
      {currentStep === 4 && (
        <Step4
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
          currentStep={currentStep}
          english={english}
          apiUrl={apiUrl}
        />
      )}
    </div>
  );
}

export default SignUpEmployeeNew;
