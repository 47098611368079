import React, { useState, useEffect, useContext } from "react";
import { Context } from "./App.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMoneyBill,
  faLayerGroup,
  faLocationDot,
  faCommentsDollar,
  faComment,
  faTrainSubway,
  faCircleExclamation,
  faCommentDots,
  faSeedling,
  faCalendar,
  faCalendarDays,
  faUsers,
  faClock,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import shirt from "./images/shirt.png";
import map from "./images/map.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import workshopIcon from "./images/companyDefault.jpg";
import LanguageComponent from "./components/LanguageComponent.jsx";
import image3 from "./images/banner.png";
import horizontalImage from "./images/testimage3.jpg";

function WorkshopDetails2(props) {
  const navigate = useNavigate();

  const location = useLocation();
  const workshopID = location.state;
  console.log("this is workshop ID: ", workshopID);
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [modal, setmodal] = useState(false);
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [endTime, setEndTime] = useState("");
  const [eventName, setEventName] = useState("");
  const [fee, setFee] = useState("");
  const [highlight, setHighLight] = useState([]);
  const [language, setLanguage] = useState("");
  const [userID, setUserID] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [applied, setApplied] = useState(false);
  const [userWorkshopArray, setUserWorkshopArray] = useState([]);

  const fakeData = {
    workshopName: "Sewing Workshop",
    date: "09/01/24",
    fee: 0,
    location: "Shing Yip Street, Shing Yip Industrial Building,  Kwun Tong",
    startTime: "11:00",
    endTime: "20:00",
    pax: 25,
    orgName: "Hong Kong Woman Centre",
    workshopDetails:
      "Join us for an immersive and hands-on sewing workshop designed to unleash your creativity and empower you with essential sewing skills. Whether you're a complete beginner or an intermediate sewist looking to expand your knowledge, this workshop is perfect for anyone with a passion for fashion, crafts, or simply the joy of creating.",
    image: image3,
    highlight: [
      "Introduction to sewing",
      "Pattern Reading and Altercations",
      "Mastering Sewing Techniques",
      "Garment Construction",
    ],
    instructor: {
      name: "Akiko Hui",
      title: "10 Years of Experience in Sewing",
      experience: "50+ Workshop Experience",
    },
  };

  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };

  const handleOverlay = () => {
    setmodal(!modal);
  };

  const applyJobButton = (
    <button className="apply-job-btn" onClick={handleOverlay}>
      {english ? "Apply" : "應徵"}
    </button>
  );

  const appliedJobButton = (
    <button className="apply-job-btn" disabled={applied ? true : false}>
      {english ? "Applied" : "已應徵"}
    </button>
  );

  useEffect(() => {
    // Gets data for that particular workshop
    axios.get(`${apiUrl}/workshop/${workshopID}`, config).then((res) => {
      console.log(
        "This is data from /workshop/workshopID",
        res.data.data.workshops
      );
      // console.log(res.data.data.workshops.company.companyName);
      setCompanyName(res.data.data.workshops.company.companyName);
      setEventName(res.data.data.workshops.eventName);
      setFee(res.data.data.workshops.fee);
      setLanguage(res.data.data.workshops.language);
      setAddress(res.data.data.workshops.address);
      setDate(res.data.data.workshops.date);
      setStartTime(res.data.data.workshops.startTime);
      setEndTime(res.data.data.workshops.endTime);
      setVacancy(res.data.data.workshops.vacancy);
      setDescription(res.data.data.workshops.description);
      setHighLight(res.data.data.workshops.highlight);
    });
    // Get userID so I can use it to post
    axios.get(`${apiUrl}/user/me`, config).then((res) => {
      console.log("This is userID",res.data.data.users.id);
      setUserID(res.data.data.users.id);
    });
    // Checks if userArray has the workshopID
    if (userWorkshopArray.includes(workshopID)) {
      console.log("Applied");
      setApplied(true);
    } else {
      console.log("not applied");
    }
  }, [userWorkshopArray]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkFree = () => {
    if (fee == 0) {
      return "FREE";
    } else {
      return fee;
    }
  };

  const applyForWorkshop = () => {
    axios
      .post(`${apiUrl}/workshop/${workshopID}/booking/workshop`, null, config)
      .then((res) => {
        console.log(res);
        console.log("Successfully posted Workshop, redirect to user profile");
        navigate("/user/profile/temp");
      })
      .catch((err) => {
        console.log(
          `posted to ${apiUrl}/workshop/${workshopID}/booking/workshop but failed`,
          err
        );
      });
  };

  const applyJobOverlay = (
    <div className="div">
      <div className="overlay" onClick={handleOverlay}></div>
      <div className="white-container">
        <div className="title">確定應徵?</div>
        <img src={workshopIcon} alt="" />
        <div className="body-text">
          {" "}
          {`${companyName}`} - {`${eventName}`}
        </div>

        <div className="body-text">
          {date} | {startTime} - {endTime} | Fee: {fee}
        </div>
        <div className="button-container">
          <button onClick={handleOverlay}>取消</button>
          <button onClick={applyForWorkshop}>確定</button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="workshop-details-container">
      <LanguageComponent />
      {modal ? applyJobOverlay : null}
      <div className="top-container">
        <Link to="/workshop">
          <button>回去</button>
        </Link>
        <div className="job-title h1">
          {english ? "Workshop Details" : "工作坊詳情"}
        </div>
      </div>
      <div className="section-container-1">
        <div className="img-container">
          <img src={horizontalImage} alt="" />
        </div>
        <div className="text-container">
          <div className="title">{eventName}</div>
          <div className="sub-title">Hosted by {companyName}</div>
        </div>
      </div>
      <div className="details-container">
        <div className="left">
          <div className="job-summary">
            <div className="title">Workshop Detail</div>
            <div className="summary-items">
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faCommentsDollar} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Fee" : "費用"}</div>
                  <div className="pay">{checkFree()}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faLanguage} />
                </div>
                <div className="text">
                  <div className="subtitle h3">
                    {english ? "Language" : "語言"}
                  </div>
                  <div className="pay">{language && language.join(", ")}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="text">
                  <div className="subtitle h3">
                    {english ? "Location" : "地點"}
                  </div>
                  <div className="pay">{address}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faCalendarDays} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Date" : "日期"}</div>
                  <div className="pay">{date}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Time" : "時間"}</div>
                  <div className="pay">
                    {startTime} - {endTime}
                  </div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="text">
                  <div className="subtitle h3">
                    {english ? "Vacancy" : "人數"}
                  </div>
                  <div className="pay">{vacancy}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container-2">
        <img src={horizontalImage} alt="" />
      </div>
      <div className="section-container-3">{description}</div>
      <div className="section-container-4">
        <div className="title">Highlight</div>
        <div className="highlight-container">
          {highlight.map((item, index) => {
            return (
              <div key={index} className="highlight-item">
                - {item}
              </div>
            );
          })}
        </div>
      </div>
      <div className="section-container-5">
        {/* <div className="title">Your Instructor</div>
        <div className="instructor-card">
          <div className="img-container">
            <img src={horizontalImage} alt="" />
          </div>
          <div className="text-container">
            <div className="name">Akiko Hui</div>
            <div className="description">
              10 Years of Experience in Sewing | 50 + Workshop Experience
            </div>
          </div>
        </div> */}
        {applied ? appliedJobButton : applyJobButton}
      </div>
    </div>
  );
}

export default WorkshopDetails2;
