import axios from "axios";
import React, { useState, useContext } from "react";
import { useNavigate, redirect } from "react-router-dom";
import { Context } from "./App.jsx";
import LanguageComponent from "./components/LanguageComponent.jsx";

function ForgetPassword() {
  const navigate = useNavigate();
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  function toLogin() {
    navigate("/login");
  }
  const [errors, setErrors] = useState({});
  const [employerState, setEmployerState] = useState(true);
  function toggleTrue() {
    setEmployerState(true);
  }
  function toggleFalse() {
    setEmployerState(false);
  }

  function handleSignUpChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.firstName.trim()) {
      validationErrors.firstName = "First Name Required";
    }
    if (!formData.lastName.trim()) {
      validationErrors.lastName = "Last Name Required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email Required";
    }
    if (!formData.password.trim()) {
      validationErrors.password = "Password Is Required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password Should Be At Least 8 Characters";
    }

    if (formData.password != formData.confirmPassword) {
      validationErrors.confirmPassword = "Password Does Not Match";
    }
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      axios
        .post(apiUrl + "/user/signup", formData)
        .then((res) => {
          navigate("/");
        })
        .catch((err) => console.log(err));
    }
    console.log(formData);
  }

  const workerTitle = (
    <div className="flex-header">
      {english ? "Flex - Employee" : "Flex - 僱員"}
    </div>
  );
  const employerTitle = (
    <div className="flex-header">
      {english ? "Flex - Employer" : "Flex - 僱主"}
    </div>
  );
  const workerFields = (
    <div className="field-wrapper">
      <input
        type="text"
        placeholder="Email Address"
        name="email"
        onChange={handleSignUpChange}
      />
      {errors.email && <span>{errors.email}</span>}
    </div>
  );
  const employerFields = (
    <div className="field-wrapper">
      <input
        type="text"
        placeholder="Email Address"
        name="email"
        onChange={handleSignUpChange}
      />
      {errors.email && <span>{errors.email}</span>}
      {errors.password && <span>{errors.password}</span>}
    </div>
  );

  return (
    <div className="sign-up-container flex">
      <LanguageComponent />
      <div className="left">
        <div className="wrapper">
          {employerState ? workerTitle : employerTitle}
          <div className="flex-subtitle"></div>
          <div className="flex-body">
            {english
              ? "Flexible jobs at the tip of your fingers"
              : "彈性工作 一鍵擁有"}
          </div>
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          {" "}
          <div className="flex-header">
            {english ? "Forgot Password" : "忘記帳戶"}
          </div>
          <div className="flex tab-container">
            <div
              className={employerState ? "active" : null}
              onClick={toggleTrue}
            >
              {english ? "Employee" : "僱員"}
            </div>
            <div
              className={!employerState ? "active" : null}
              onClick={toggleFalse}
            >
              {english ? "Employer" : "僱主"}
            </div>
          </div>
          <form
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="login-container flex-column">
              {employerState ? workerFields : employerFields}
              <input
                type="submit"
                value={english ? "Reset Password" : "重設密碼"}
              />
              <div className="decor">
                <div className="line"></div>
                <div className="text">{english ? "or" : "或"}</div>
                <div className="line"></div>
              </div>
              <div className="login-button" onClick={toLogin}>
                {english ? "Back to Login" : "返回登入"}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
