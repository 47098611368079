import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-regular-svg-icons";
import companyIcon from "./images/companyicon.png";
import { useNavigate, redirect } from "react-router-dom";
import LanguageComponent from "./components/LanguageComponent.jsx";
import axios from "axios";
import ListingItem from "./ListingItem";
import WorkshopListingItem from "./WorkshopListingItem";
import { Context } from "./App.jsx";
import Navbar from "./components/Navbar.jsx";
import PastJobsListingComponent from "./components/PastJobsListingComponent.jsx";
import testImage from "./images/workshopvenue.jpeg";
import StarsDisplay from "./components/StarsDisplay.jsx";

function UserProfile() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [finalImagePath, setFinalImagePath] = useState("");
  const [listing, setListing] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userDetails, setUserDetails] = useState("");
  const [confirmedJobs, setConfirmedJobs] = useState([]);
  const [userPhoto, setUserPhoto] = useState("");
  const [workshopData, setWorkshopData] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [pastExperiences, setPastExperiences] = useState([]);
  const [rejectedJobs, setRejectedJobs] = useState([]);

  const navigate = useNavigate();
  // Used for Translating Best Five
  const totalRowData = [
    "聆聽他人",
    "適應變化",
    "換位思考",
    "責任心",
    "系統思考",
    "溝通協作",
    "耐性",
    "時間管理",
    "開放思",
    "有愛心",
    "數據錄入",
    "寫作",
    "簡報製作",
    "檔案管理",
    "烘焙",
    "清潔",
    "育兒",
    "客戶服務",
  ];

  const totalRowDataEng = [
    "Listening to Others",
    "Adaptability",
    "Empathy",
    "Responsibility",
    "Systems Thinking",
    "Communication and Collaboration",
    "Patience",
    "Time Management",
    "Open-mindedness",
    "Compassion",
    "Data Entry",
    "Writing",
    "Presentation Creation",
    "File Management",
    "Baking",
    "Cleaning",
    "Childcare",
    "Customer Service",
  ];

  function toHome() {
    navigate("/employee/job");
  }

  function toEditProfile() {
    navigate("/user/edit/profile");
  }
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  useEffect(() => {
    axios
      .get(apiUrl + "/user/me", config)
      .then((res) => {
        console.log("This is the data from user/me", res.data.data);
        console.log(res.data.data.rejected_jobs);
        const dataPath = res.data.data;
        setRejectedJobs(res.data.data.rejected_jobs);
        console.log(dataPath.applied_jobs);
        setConfirmedJobs(dataPath.confirmed_jobs);
        console.log(res.data.data.users);
        setUserData(res.data.data.users);
        console.log("This is Applied Jobs: ", res.data.data.applied_jobs);
        setListing(res.data.data.applied_jobs);
        console.log(res.data.data.users.userPhoto);
        setProfilePic(apiUrl + "/img/users/" + res.data.data.users.userPhoto);
        console.log(res.data.data.users.pastExperience);
        setPastExperiences(res.data.data.users.pastExperience);
        // const workshopPath = res.data.data.workshops;
        // setWorkshopData([...workshopPath]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="profile-user-container">
      <LanguageComponent />
      <Navbar />
      <div className="back-home flex">
        <div className="left"></div>
      </div>
      <div className="flex profile-container">
        <div className="left">
          <div className="image">
            <img src={profilePic} alt="" />
            {/* <img src={userPhoto} alt="" /> */}
          </div>
          <div className="header">
            {userData.firstName} {userData.lastName}
          </div>
          <div className="text-container"></div>
          <div className="star-rating">
            <StarsDisplay rating={userData.ratingsAverage} />
            <div className="reviews">
              (<a href="/user/reviews/new">{userData.ratingsQuantity} Reviews</a>)
            </div>
          </div>
          <div className="sub-header">
            {english ? "Personal Information" : "個人資料"}
          </div>
          <div className="grid-container">
            <div className="grid-item">
              <div className="grey-body">{english ? "Email" : "電郵地址"}</div>
              <div className="body-text">
                {userData ? userData.email : null}
              </div>
            </div>
            <div className="grid-item">
              {" "}
              <div className="grey-body">{english ? "Phone" : "電話號碼"}</div>
              <div className="body-text">
                {userData && userData.phoneNumber}
              </div>
            </div>
            <div className="grid-item">
              {" "}
              <div className="grey-body">
                {english ? "Preferred Location" : "偏好地點"}
              </div>
              <div className="body-text">
                {userData.preferredDistrict &&
                  userData.preferredDistrict.join(" | ")}
              </div>
            </div>
            <div className="grid-item">
              <div className="grey-body">{english ? "Language" : "語言"}</div>
              <div className="body-text">
                {userData?.languages && userData.languages.join(" | ")}
              </div>
            </div>
          </div>

          <div className="sub-header">
            {english ? "Best 5 Descriptor" : "5個最能代表您的形容詞"}
          </div>
          <div className="descriptor-container">
            {userData?.descriptors &&
              userData?.descriptors.map((item) => {
                // Find the index of the item in totalRowData
                const index = totalRowData.indexOf(item);
                // Get the English translation using the index
                const displayItem =
                  english && index !== -1 ? totalRowDataEng[index] : item;

                return (
                  <div key={item} className="descriptor-item">
                    {displayItem}
                  </div>
                );
              })}
          </div>
          <div className="edit-button-container">
            <button onClick={() => toEditProfile()}>
              {english ? "Edit Profile" : "改個人資料"}
            </button>
          </div>
        </div>
        <div className="right">
          <div className="upcoming-container">
            <div className="header">
              {english ? "Your Upcoming Job" : "您確認的工作"}
            </div>
            <div className="body-text">
              {english
                ? "Your Upcoming Job(s) Appears Here"
                : "以下是您確認的工作"}
            </div>
            <div className="listing-container">
              {confirmedJobs &&
                confirmedJobs.map((item) => {
                  console.log("This is confirmed Jobs: ", confirmedJobs);
                  return (
                    <ListingItem
                      key={item.job_id}
                      companyName={item.company.companyName}
                      jobID={item.job._id}
                      pay={item.job.pay}
                      jobTitle={item.job.jobTitle}
                      startTime={item.job.startTime}
                      endTime={item.job.endTime}
                      location={item.job.location}
                      date={item.job.date.slice(5)}
                      employee={true}
                      logo={item.company.logo}
                    />
                  );
                })}
            </div>
          </div>
          <div className="upcoming-container">
            <div className="header">
              {english ? "Your Upcoming Workshop" : "您即將參與的工作坊"}
            </div>
            <div className="body-text">
              {english
                ? "Here are your upcoming workshop(s)"
                : "以下是您即將參加的工作坊"}
            </div>
            <div className="listing-container">
              {/* {workshopData &&
                workshopData.map((item) => {
                  return (
                    <WorkshopListingItem
                      key={item._id}
                      companyName={item.workshop.companyName}
                      jobID={item.workshop._id}
                      pay={item.workshop.fee}
                      jobTitle={item.workshop.eventName}
                      startTime={item.workshop.startTime}
                      endTime={item.workshop.endTime}
                      location={item.workshop.address}
                      date={item.workshop.date.slice(5)}
                      employee={true}
                    />
                  );
                })} */}
            </div>
          </div>
          <div className="upcoming-container">
            <div className="header">
              {english ? "Your Pending Job" : "您等待確認的工作"}
            </div>
            <div className="body-text">
              {english
                ? "Your Pending Job Appears Here"
                : "以下是您等待確認的工作"}
            </div>
            <div className="listing-container">
              {listing &&
                listing.map((item, index) => {
                  console.log("This is item in pending job", item);
                  console.log("this is logo:", item.company.logo);
                  return (
                    <ListingItem
                      key={index}
                      companyName={item.company.companyName}
                      jobID={item.job?.id}
                      pay={item.job?.pay}
                      jobTitle={item.job?.jobTitle}
                      startTime={item.job?.startTime}
                      endTime={item.job?.endTime}
                      location={item.job?.location}
                      date={item.job?.date.slice(5)}
                      employee={true}
                      logo={item.company.logo}
                    />
                  );
                })}
            </div>
          </div>
          <div className="upcoming-container">
            <div className="header">
              {english ? "Your Past Job Experience" : "你之前的工作經驗"}
            </div>
            <div className="body-text">
              {english
                ? "Here are your past job(s) experiences"
                : "以下是您即將參加的工作坊"}
            </div>
            <div className="listing-container">
              {pastExperiences &&
                pastExperiences.map((item, index) => {
                  return (
                    <PastJobsListingComponent
                      key={index}
                      jobTitle={item.jobTitle}
                      startDate={item.startDate}
                      endDate={item.endDate}
                      imageSrc={testImage}
                      companyName={item.companyName}
                      jobDescription={item.jobDescription}
                    />
                  );
                })}
            </div>
          </div>
          <div className="upcoming-container">
            <div className="header">
              {english ? "Rejected Jobs" : "被拒絕的工作"}
            </div>
            <div className="body-text">
              {english
                ? "Here are job(s) that you are rejected from"
                : "以下是您被拒絕的工作"}
            </div>
            <div className="listing-container">
              {rejectedJobs &&
                rejectedJobs.map((item) => {
                  return (
                    <ListingItem
                      key={item.job_id}
                      companyName={item.company.companyName}
                      jobID={item.job._id}
                      pay={item.job.pay}
                      jobTitle={item.job.jobTitle}
                      startTime={item.job.startTime}
                      endTime={item.job.endTime}
                      location={item.job.location}
                      date={item.job.date.slice(5)}
                      employee={true}
                      logo={item.company.logo}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
