import React, { useEffect, useState, useContext } from "react";
import { Context } from "../App.jsx";
import { Link, useNavigate } from "react-router-dom";
import image2 from "../images/profileDefault.jpg";
import axios from "axios";

function Navbar() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [active, setActive] = useState(false);
  const userPhoto = localStorage.getItem("userPhoto");

  const navigate = useNavigate();
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const toHome = () => {
    navigate("/employee/job");
  };
  function toWorkshop() {
    navigate("/workshop");
  }
  // Single select for checkbox
  function toProfile() {
    navigate("/user/profile/temp");
  }

  const toggleActive = () => {
    setActive(!active);
    console.log("active?", active);
  };

  useEffect(() => {
    axios.get(apiUrl + "/user/me", config).then((res) => {
      console.log(res.data.data.users.userPhoto);
      localStorage.setItem("userPhoto", res.data.data.users.userPhoto);
    });
  }, []);
  return (
    <div className="navbar">
      <div className="nav">
        <div className="left">
          <div className="ham-menu" onClick={toggleActive}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div
            className={
              active ? "active off-screen-menu" : "hidden off-screen-menu"
            }
          >
            <div className="cross" onClick={toggleActive}>
              <span className="cross-1"></span>
              <span className="cross-2"></span>
            </div>
            <div className="nav-item view-profile" onClick={toProfile}>
              {english ? "Profile" : "個人檔案"}
            </div>
            <div className="nav-item" onClick={toHome}>
              {english ? "Job" : "搵工"}
            </div>
            <div className="nav-item" onClick={toWorkshop}>
              {english ? "Workshop" : "工作坊"}
            </div>
          </div>
          <img src={`${apiUrl}/img/users/${userPhoto}`} alt="" />
          <div className="nav-item-wrapper">
            <div className="nav-item view-profile" onClick={toProfile}>
              {english ? "Profile" : "個人檔案"}
            </div>
            <div className="nav-item" onClick={toHome}>
              {english ? "Job" : "搵工"}
            </div>
            <div className="nav-item" onClick={toWorkshop}>
              {english ? "Workshop" : "工作坊"}
            </div>
          </div>
        </div>
        <div className="nav-item" onClick={() => navigate("/login")}>
          {english ? "Logout" : "登出"}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
