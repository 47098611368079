import React, { useEffect, useState, useContext } from "react";
import { Context } from "./App.jsx";
import { Link, useNavigate } from "react-router-dom";
import LanguageComponent from "./components/LanguageComponent.jsx";
import image from "./images/logo1.png";
import image2 from "./images/profileDefault.jpg";
import image3 from "./images/banner.png";
import axios from "axios";
import JobComponent from "./components/JobComponent.jsx";
import { faBiking } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Navbar from "./components/Navbar.jsx";
import DropdownLocationFilter from "./components/DropdownLocationFilter.jsx";
import DropdownCategoryFilter from "./components/DropdownCategoryFilter.jsx";

// React - Date - Range
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, DateRange } from "react-date-range";

function HomePage() {
  // States
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  // Fake Data for Testing
  const [fakeDisplay, setFakeDisplay] = useState([]);
  const [fakeJobData, setFakeJobData] = useState([
    { name: "job1", date: "2024-08-02", district: "離島區", category: "清潔" },
    { name: "job2", date: "2024-08-03", district: "離島區", category: "紡織" },
    { name: "job3", date: "2024-08-02", district: "沙田區", category: "紡織" },
    { name: "job3", date: "2024-08-03", district: "沙田區", category: "清潔" },
    { name: "job5", date: "2024-08-04", district: "沙田區", category: "清潔" },
  ]);
  const [dates, setDates] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [jobID, setJobID] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]); // Category Filter
  const [locationFilter, setLocationFilter] = useState([]); // Location Filter
  const [dateFilters, setDateFilters] = useState([]); // Date Filter
  const [filteredItems, setFilteredItems] = useState(jobData); // Filtered Items
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const clearDates = () => {
    // Reset dateState to its initial value
    setDateState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    setDateFilters([]);
  };

  // Used for Translating Districts
  const districtEnglish = [
    "Islands",
    "Kwai Tsing",
    "North",
    "Sai Kung",
    "Sha Tin",
    "Tai Po",
    "Tsuen Wan",
    "Tuen Mun",
    "Yuen Long",
    "Kowloon City",
    "Kwun Tong",
    "Sham Shui Po",
    "Wong Tai Sin",
    "Yau Tsim Mong",
    "Central and Western",
    "Eastern",
    "Southern",
    "Wan Chai",
  ];

  const districtChinese = [
    "離島區",
    "葵青區",
    "北區",
    "西貢區",
    "沙田區",
    "大埔區",
    "荃灣區",
    "屯門區",
    "元朗區",
    "九龍城區",
    "觀塘區",
    "深水埗區",
    "黃大仙區",
    "油尖旺區",
    "中西區",
    "東區",
    "南區",
    "灣仔區",
  ];
  const jobCategoryEng = ["Cleaning", "Logistics", "Food & Beverage", "Retail"];

  const jobCategoryChi = ["清潔", "物流及運輸", "餐飲", "零售"];

  const navigate = useNavigate();
  const toHome = () => {
    navigate("/employee/job");
  };
  function toWorkshop() {
    navigate("/workshop");
  }
  // Single select for checkbox
  function toProfile() {
    navigate("/user/profile/temp");
  }
  const singleSelectCheckboxes = document.querySelectorAll(".single-select");
  // Add click event listener to each checkbox
  singleSelectCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener("click", function () {
      // Uncheck all other checkboxes in the group
      singleSelectCheckboxes.forEach((cb) => {
        if (cb !== this) {
          cb.checked = false;
        }
      });
    });
  });

  // useEffect to get data from backend
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  useEffect(() => {
    axios
      .get(apiUrl + "/job", config)
      .then((res) => {
        console.log("This is config: ", config);
        console.log(res.data.data.jobs);
        setJobData(res.data.data.jobs);
        res.data.data.jobs.map((item) =>
          setJobID((prev) => [...prev, item._id])
        );
        // res.data.data.
        // console.log(res.data.data.jobs[0]._id) <-- ID
      })
      .catch((err) => {
        console.log("This is config: ", config);
        console.log(localStorage.token);
        return console.log(err);
      });
  }, []);

  useEffect(() => {
    jobData.map((item) => setFilteredItems((prev) => [...prev, item]));
  }, [jobData]);

  useEffect(() => {
    axios.get(apiUrl + "/user/me", config).then((res) => {
      // For debugging use
      // console.log(res);
      // const profilePicUrl =
      //   "http://localhost:4000/img/users/" +
      //   res.data.data.users.details[0].userPhoto;
      // localStorage.setItem("profilePic", profilePicUrl);
    });
  }, []);

  // Handle Category
  const handleCategory = (e) => {
    console.log(e.target.value);
    if (categoryFilter.includes(e.target.value)) {
      document.getElementById("category").selectedIndex = 0;
      console.log("category exists");
    } else {
      setCategoryFilter([...categoryFilter, e.target.value]);
      document.getElementById("category").selectedIndex = 0;
    }
  };
  // Handle Remove Category
  const removeCategory = (index) => {
    const updatedCategory = [...categoryFilter];
    updatedCategory.splice(index, 1);
    setCategoryFilter(updatedCategory);
  };
  // Handle Location Filter Function
  const handleLocation = (e) => {
    if (locationFilter.includes(e.target.value)) {
      document.getElementById("homepage-location").selectedIndex = 0;
      console.log("location exists");
    } else {
      setLocationFilter([...locationFilter, e.target.value]);
      document.getElementById("homepage-location").selectedIndex = 0;
    }
  };

  const handleRemoveLocation = (e) => {
    setLocationFilter([
      ...locationFilter.filter((item) => item != e.target.value),
    ]);
  };

  // Function to get dates in range
  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate);
    const dates = [];

    while (date <= new Date(endDate)) {
      const formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      dates.push(formattedDate);
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }
  // Handle Date Filter whenever someone touches the date filter
  const handleDateFilter = (e) => {
    setDateState([e.selection]);

    const selectedDates = getDatesInRange(
      e.selection.startDate,
      e.selection.endDate
    );
    setDateFilters(selectedDates);
    // console.log(selectedDates);
  };

  // UseEffect for FakeData
  // Filter Use Effect Final
  //  WORKS NOW, CAN TRANSFER TO REAL DATA -------
  useEffect(() => {
    let updatedJobData = jobData; // Start with the original job data

    // Apply location filter
    if (locationFilter.length > 0) {
      updatedJobData = updatedJobData.filter((job) =>
        locationFilter.includes(job.district)
      );
    }

    // Apply category filter
    if (categoryFilter.length > 0) {
      updatedJobData = updatedJobData.filter((job) =>
        categoryFilter.includes(job.category)
      );
    }

    // Apply date filter
    if (dateFilters.length > 0) {
      updatedJobData = updatedJobData.filter((job) =>
        dateFilters.includes(job.date)
      );
    }

    // Set the filtered items
    setFilteredItems(updatedJobData);
  }, [locationFilter, categoryFilter, dateFilters, jobData]);

  // Handle Date Select
  const handleDateSelect = (e) => {
    console.log(e);
  };

  return (
    <div className="homepage-container">
      <LanguageComponent />
      <Navbar />
      <div className="flex-container">
        <div className="left">
          <div className="find-a-job-container">
            <div className="title header-2">
              {english ? "Find a Job" : "尋找工作"}
            </div>
            <div className="sub-title header-3">
              {" "}
              {english ? "District" : "地區"}
            </div>
            <DropdownLocationFilter
              setLocationFilter={setLocationFilter}
              locationFilter={locationFilter}
            />
            <div className="category-filter">
              <div className="sub-title header-3">
                {english ? "Job Category" : "工種"}
              </div>
              <div className="category-items">
                {categoryFilter.map((item, index) => {
                  // Find the index of the item in totalRowData
                  const index2 = jobCategoryChi.indexOf(item);
                  // Get the English translation using the index
                  const displayItem =
                    english && index2 !== -1 ? jobCategoryEng[index2] : item;

                  return (
                    <div
                      className="category-item"
                      key={index}
                      onClick={() => removeCategory(index)}
                    >
                      <div className="text">{displayItem}</div>
                      <div className="icon">
                        {" "}
                        <FontAwesomeIcon icon={faCircleXmark} />
                      </div>
                    </div>
                  );
                })}
              </div>

              <select name="category" id="category" onChange={handleCategory}>
                <option value="" selected disabled>
                  {english
                    ? "Select your Preferred Industry"
                    : "選擇您喜歡的工種"}
                </option>
                <option value="清潔">{english ? "Cleaning" : "清潔"}</option>
                <option value="物流及運輸">
                  {english ? "Logistics" : "物流及運輸"}
                </option>
                <option value="餐飲">
                  {english ? "Food & Beverage" : "餐飲"}
                </option>
                <option value="零售">{english ? "Retail" : "零售"}</option>
              </select>
            </div>
            <div className="sub-title header-3">
              {english ? "Date" : "日子"}
            </div>
            <div className="day-filter-draft">
              <DateRange
                editableDateInputs={true}
                onChange={handleDateFilter}
                moveRangeOnFirstSelection={false}
                ranges={dateState}
              />
            </div>
            <div className="clear-date-button-container">
              <button className="clear-date-button" onClick={clearDates}>
                {english ? "Reset Date Filter" : "重置日期"}
              </button>
            </div>
          </div>
          <div className="banner-container">
            <img src={image3} alt="" />
          </div>
        </div>
        <div className="right">
          {filteredItems.map((item, index) => {
            console.log(item.id); // Log item.id here
            return (
              <JobComponent
                key={index}
                district={filteredItems[index].district}
                date={filteredItems[index].date.slice(5)}
                jobTitle={filteredItems[index].jobTitle}
                companyName={
                  item.company && item.company.companyName
                    ? item.company.companyName
                    : "No Company Name"
                }
                location={filteredItems[index].location}
                tags={filteredItems[index].tags}
                startTime={filteredItems[index].startTime}
                endTime={filteredItems[index].endTime}
                pay={filteredItems[index].pay}
                jobID={item.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
