import React, { useState, useEffect, useContext } from "react";
import { Context } from "./App.jsx";
import NavbarEmployer from "./components/NavbarEmployer.jsx";
import testimg from "./images/600x400.jpg";
import SelectedJobsComponent from "./components/SelectedJobsComponent.jsx";
import { useLocation } from "react-router-dom";
import axios from "axios";
import WorkerListingComponent from "./components/WorkerListingComponent.jsx";

function ViewWorkshopSignupEmployer() {
  const [selectedJob, setSelectedJob] = useState();
  const [appliedCandidates, setAppliedCandidates] = useState();
  const [confirmedCandidates, setConfirmedCandidates] = useState();
  const [rejectedCandidates, setRejectedCandidates] = useState();
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const companyPic = localStorage.getItem("profilePic");

  const location = useLocation();
  const workshopID = location.state?.workshopID; // Use optional chaining to avoid errors if state is undefined
  console.log("This is workshopID", workshopID);
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };

  const user = {
    applied: [
      {
        username: "",
        password: "",
        bestFive: "",
      },
    ],
  };

  useEffect(() => {
    axios
      .get(apiUrl + `/workshop/${workshopID}/candidates`, config)
      .then((res) => {
        console.log("This is the data for candidate/workshop", res.data.data);
        setSelectedJob(res.data.data.workshop);
        setAppliedCandidates(res.data.data.applied);
        setConfirmedCandidates(res.data.data.confirmed);
        setRejectedCandidates(res.data.data.rejected);
      });
    // axios
    //   .get(apiUrl + `/job/${jobID}/candidates`, config)
    //   .then((res) => {
    //     console.log(res.data.data);
    //     setSelectedJob(res.data.data.job);
    //     console.log(res.data.data.applied);
    //     setAppliedCandidates(res.data.data.applied);
    //     setConfirmedCandidates(res.data.data.confirmed);
    //     console.log(res.data.data.rejected);
    //     setRejectedCandidates(res.data.data.rejected);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, [workshopID]);

  return (
    <div className="view-candidate">
      <NavbarEmployer />
      <div className="grid-container">
        {selectedJob ? (
          <SelectedJobsComponent
            jobTitle={selectedJob.eventName}
            date={selectedJob.date}
            startTime={selectedJob.startTime}
            endTime={selectedJob.endTime}
            pay={selectedJob.fee}
            image={apiUrl + `/img/employers/logo/${companyPic}`}
          />
        ) : null}

        <div className="confirmed-workers-container">
          <div className="title">
            Confirmed Workers (
            {confirmedCandidates && confirmedCandidates.length})
          </div>
          <div className="worker-container">
            {confirmedCandidates &&
              confirmedCandidates.map((item, index) => {
                const userData = item.userDetails[0];
                console.log("this is userData: ", userData);
                return (
                  <WorkerListingComponent
                    key={index}
                    firstName={userData.firstName}
                    lastName={userData.lastName}
                    userID={userData.ID}
                    // jobID={jobID}
                    confirmed={true}
                    userPhoto={userData.userPhoto}
                    workshop={true}
                    workshopID={workshopID}
                  />
                );
              })}
          </div>
        </div>
        <div className="candidates-container">
          <div className="title">
            Candidates ({appliedCandidates && appliedCandidates.length})
          </div>
          <div className="worker-container">
            {appliedCandidates &&
              appliedCandidates.map((item, index) => {
                const userData = item.userDetails[0];
                console.log(userData);
                return (
                  <WorkerListingComponent
                    key={index}
                    firstName={userData.firstName}
                    lastName={userData.lastName}
                    userID={userData.ID}
                    // jobID={jobID}
                    candidate={true}
                    userPhoto={userData.userPhoto}
                    workshop={true}
                    workshopID={workshopID}
                  />
                );
              })}
          </div>
        </div>
        <div className="candidates-container">
          <div className="title">
            Rejected Candidates (
            {rejectedCandidates && rejectedCandidates.length})
          </div>
          <div className="worker-container">
            {rejectedCandidates &&
              rejectedCandidates.map((item, index) => {
                // Use optional chaining to safely access userDetails[0]
                const userData = item.userDetails[0];
                console.log(userData);
                // Check if userData is defined before trying to access its properties
                if (!userData) {
                  console.warn(
                    `No user details for item at index ${index}`,
                    item
                  );
                  return null; // Skip rendering if userData is not available
                }

                return (
                  <WorkerListingComponent
                    key={index}
                    firstName={userData.firstName}
                    lastName={userData.lastName}
                    userID={userData.id}
                    // jobID={jobID}
                    rejected={true}
                    userPhoto={userData.userPhoto}
                    workshop={true}
                    workshopID={workshopID}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewWorkshopSignupEmployer;
