import React from "react";
import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-regular-svg-icons";
import companyIcon from "./images/companyicon.png";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import LanguageComponent from "./components/LanguageComponent.jsx";
import axios from "axios";
import ListingItem from "./ListingItem.jsx";
import WorkshopListingItem from "./WorkshopListingItem.jsx";
import { Context } from "./App.jsx";
import NavbarEmployer from "./components/NavbarEmployer.jsx";

function EmployerHome() {
  const navigate = useNavigate();
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [finalImagePath, setFinalImagePath] = useState("");
  const [workshopData, setWorkshopData] = useState([]);
  const [employerData, setEmployerData] = useState([]);
  const [jobListing, setJobListing] = useState([]);
  const profilePic = localStorage.getItem("profilePic");
  const [pastJobs, setPastJobs] = useState([]);

  function toHome() {
    navigate("/employee/job");
  }
  const toEditProfile = () => {
    navigate("/employer/edit/profile");
  };

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },
  };



  useEffect(() => {
    axios
      .get(apiUrl + "/employer/me", config)
      .then((res) => {
        console.log("Data from /employer/me", res);
        setJobListing(res.data.data.postedJobs);
        setEmployerData(res.data.data.employer);
        setWorkshopData(res.data.data.postedWorkshops);
        setPastJobs(res.data.data.pastJobs);
        const profilePicUrl = res.data.data.employer.logo;
        localStorage.setItem("profilePic", profilePicUrl);
        // const workshopPath = res.data.data.workshops;
        // setWorkshopData([...workshopPath]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [profilePic]);

  return (
    <div className="profile-user-container">
      <LanguageComponent />
      <NavbarEmployer />
      <div className="back-home flex">
        <div className="left"></div>
      </div>
      <div className="flex profile-container">
        <div className="left">
          <div className="image">
            <img
              crossOrigin="anonymous"
              src={apiUrl + `/img/employers/logo/${profilePic}`}
              alt=""
            />
            {/* <img src={userPhoto} alt="" /> */}
          </div>
          <div className="header"></div>
          <div className="star-rating"></div>
          <div className="sub-header">
            {english ? "Company Information" : "公司資料"}
          </div>
          <div className="grid-container">
            <div className="grid-item">
              <div className="grey-body">
                {english ? "Company Name" : "業務名稱"}
              </div>
              <div className="body-text">
                {employerData ? employerData.companyName : null}
              </div>
            </div>
            <div className="grid-item">
              <div className="grey-body">
                {english ? "Legal Business Name" : "公司名稱"}
              </div>
              <div className="body-text">
                {employerData ? employerData.legalBusinessName : null}
              </div>
            </div>
            <div className="grid-item">
              <div className="grey-body">
                {english ? "Business Address" : "公司地址"}
              </div>
              <div className="body-text">
                {employerData ? employerData.address : null}
              </div>
            </div>
            <div className="grid-item">
              <div className="grey-body">
                {english ? "Phone Number" : "電話號碼"}
              </div>
              <div className="body-text">
                {employerData ? employerData.contactNumber : null}
              </div>
            </div>
          </div>
          <div className="edit-button-container">
            <button onClick={() => toEditProfile()}>
              {english ? "Edit Profile" : "改個人資料"}
            </button>
          </div>
        </div>
        <div className="right">
          <div className="upcoming-container">
            <div className="header">
              {english ? "Past Job(s)" : "過去的工作"}
            </div>
            <div className="body-text">
              {english
                ? "Your Past Jobs are Listed Here"
                : "以下是您過去新增的工作"}
            </div>
            <div className="listing-container">
              {pastJobs &&
                pastJobs.map((item) => {
                  return (
                    <ListingItem
                      key={item._id}
                      companyName={employerData.companyName}
                      jobID={item._id}
                      pay={item.pay}
                      jobTitle={item.jobTitle}
                      startTime={item.startTime}
                      endTime={item.endTime}
                      location={item.location}
                      date={item.date.slice(5)}
                      employee={false}
                      logo={profilePic}
                      pastJob={true}
                    />
                  );
                })}
            </div>
          </div>
          <div className="upcoming-container">
            <div className="header">
              {english ? "Your Pending Job" : "您新增的工作"}
            </div>
            <div className="body-text">
              {english ? "Your Listed Job Appears Here" : "以下是您新增的工作"}
            </div>
            <div className="listing-container">
              {jobListing &&
                jobListing.map((item) => {
                  return (
                    <ListingItem
                      key={item._id}
                      companyName={employerData.companyName}
                      jobID={item._id}
                      pay={item.pay}
                      jobTitle={item.jobTitle}
                      startTime={item.startTime}
                      endTime={item.endTime}
                      location={item.location}
                      date={item.date.slice(5)}
                      employee={false}
                      logo={profilePic}
                    />
                  );
                })}
            </div>
          </div>
          <div className="upcoming-container">
            <div className="header">
              {english ? "Your Upcoming Workshop" : "您新增的工作坊"}
            </div>
            <div className="body-text">
              {english
                ? "Here are your upcoming workshop(s)"
                : "以下是您新增的工作坊"}
            </div>
            <div className="listing-container">
              {workshopData &&
                workshopData.map((item) => {
                  return (
                    <WorkshopListingItem
                      key={item._id}
                      companyName={employerData.companyName}
                      jobID={item._id}
                      pay={item.fee}
                      jobTitle={item.eventName}
                      startTime={item.startTime}
                      endTime={item.endTime}
                      location={item.address}
                      date={item.date.slice(5)}
                      employee={false}
                      logo={profilePic}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployerHome;
