import React from 'react'
import StarDisplay from "./StarsDisplay";
function EmployeeReviewCard(props) {
  return (
    <div className='EmployeeReviewCard'>
        <div className="top-container">
          <div className="img-container">
            <img src={props.companyImage} alt="" />
          </div>
          <div className="text-container">
            <div className="title">{props.companyName} - {props.jobName}</div>
            <div className="bottom-text-container">
              <div className="date">{props.date}</div>
              <StarDisplay rating={props.rating}/>
            </div>
          </div>
        </div>
        <div className="compliments-container">
          {props.compliments.map((item, index) => {
            return <div className="compliment" key={index}>{item}</div>
          })}
        </div>
        <div className="review-container">
          {props.reviewText}
        </div>
    </div>
  )
}

export default EmployeeReviewCard