import React, { useState, useEffect, useContext } from "react";
import { Context } from "../App.jsx";
import { Link, useLocation } from "react-router-dom";
import image from "../images/companyDefault.jpg";
import axios from "axios";

function JobComponent(props) {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const fakeData = { test: "HELLO TESTING FAKE DATA" };

  useEffect(() => {
    axios
      .get(`${apiUrl}/job/${props.jobID}`, config)
      .then((res) => {})
      .catch((err) => {
        console.log(localStorage.token);
        return console.log(err);
      });
  }, []);

  return (
    <div className="job-item">
      <Link to="/jobdetail" state={props.jobID}>
        <div className="flex space-between">
          <div className="text">
            <div className="job-title">{props.jobTitle}</div>
            <div className="company-name">
              {english ? "Company Name: " : "公司名: "}
              {props.companyName}
            </div>
            <div className="location">
              {english ? "Location: " : "地址: "}
              {props.district}
            </div>
            <div className="hours">
              {english ? "Date: " : "日期: "} {props.date}
            </div>
            <div className="time">
              {english ? "Time: " : "時間: "}
              {props.startTime} - {props.endTime}
            </div>
          </div>
          <div className="image">
            <img src={image} alt="" />
          </div>
        </div>
        <div className="flex space-between">
          <div className="tags">
            {/* <div className="tag">Experienced</div>
            <div className="tag">Cleaning</div>
            <div className="tag">Angela</div> */}
            {props.tags.map((item, index) => (
              <div key={index} className="tag">
                {item}
              </div>
            ))}
          </div>
          <div className="wage-container">
            <div className="wage">${props.pay}/hr</div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default JobComponent;
