import React, { useEffect, useContext, useState } from "react";
import { Context } from "./App.jsx";
import { useLocation } from "react-router-dom";
import NavbarEmployer from "./components/NavbarEmployer";
import SelectedJobsComponent from "./components/SelectedJobsComponent";
import ReviewComponent from "./components/ReviewComponent";
import fakeImage from "../src/assets/logo.png";
import CandidateListingComponent from "./components/CandidateListingComponent";
import axios from "axios";

function EmployerReview() {
  const location = useLocation();
  const jobID = location.state?.jobID;
  const { apiUrl } = useContext(Context);
  const [userProfiles, setUserProfiles] = useState([]);
  const [currentProfile, setCurrentProfile] = useState();
  const [jobDetails, setJobDetails] = useState();

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },
  };

  
  // Gets data for the completed jobs and candidate
  useEffect(() => {
    axios
      .get(`${apiUrl}/job/${jobID}/completed`, config)
      .then((res) => {
        console.log(
          "This is data from /job/jobid/confirmed",
          res.data.confirmed
        );
        const profiles = res.data.confirmed.map((item) => item.user);
        setUserProfiles(profiles);
      })
      .catch((error) => {
        console.error("Error fetching user profiles:", error);
      });
  }, [apiUrl, jobID]); // Make sure to add dependencies

  // Use another useEffect to set the current profile when userProfiles changes
  useEffect(() => {
    if (userProfiles.length > 0) {
      setCurrentProfile(userProfiles[0]);
    }
  }, [userProfiles]);

  const handleProfile = (index) => {
    setCurrentProfile(userProfiles[index]);
  };

  // Gets data for the selected jobs
  useEffect(() => {
    axios.get(`${apiUrl}/job/${jobID}`, config).then((res) => {
      console.log("Job data from /job/jobID", res.data.data.jobs);
      setJobDetails(res.data.data.jobs);
    });
  }, []);

  const fakeJobDetails = {
    jobTitle: "Software Developer",
    date: "2024-10-25",
    startTime: "09:00 AM",
    endTime: "05:00 PM",
    pay: "1200",
    image: fakeImage,
  };

  return (
    <div className="EmployerReview">
      <NavbarEmployer />
      <div className="grid-container">
        {jobDetails && (
          <SelectedJobsComponent
            jobTitle={jobDetails.jobTitle}
            date={jobDetails.date}
            startTime={jobDetails.startTime}
            endTime={jobDetails.endTime}
            pay={jobDetails.pay}
            image={`${apiUrl}/img/employers/logo/${jobDetails.company.logo}`}
          />
        )}

        {currentProfile && (
          <ReviewComponent
            name={currentProfile && currentProfile.firstName}
            image={`${apiUrl}/img/users/${currentProfile.userPhoto}`}
            jobID={jobID}
            userID={currentProfile.id}
          />
        )}

        <div className="candidates-container">
          <div className="title">Applied Candidates</div>
          {userProfiles.map((item, index) => (
            <CandidateListingComponent
              key={index}
              name={item.firstName}
              image={`${apiUrl}/img/users/${item.userPhoto}`}
              id={item.id}
              onClick={() => handleProfile(index)} // Pass index to handleProfile
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default EmployerReview;
