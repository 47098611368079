import React, { useEffect, useState, useContext } from "react";
import { Context } from "../App.jsx";
import { Link, useNavigate } from "react-router-dom";
import image2 from "../images/profileDefault.jpg";
import axios from "axios";

function NavbarEmployer() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [active, setActive] = useState(false);
  const profilePic = localStorage.getItem("profilePic");
  const navigate = useNavigate();

  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const toggleActive = () => {
    setActive(!active);
    console.log("active?", active);
  };
  const toHome = () => {
    navigate("/home/employer");
  };
  function toCreateJob() {
    navigate("/create/job");
  }

  function toCreateWorkshop() {
    navigate("/create/workshop");
  }

  return (
    <div className="navbar">
      <div className="nav">
        <div className="left">
          <div className="ham-menu" onClick={toggleActive}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div
            className={
              active ? "active off-screen-menu" : "hidden off-screen-menu"
            }
          >
            <div className="cross" onClick={toggleActive}>
              <span className="cross-1"></span>
              <span className="cross-2"></span>
            </div>
            <div className="nav-item view-profile" onClick={toHome}>
              {english ? "View Profile" : "個人檔案"}
            </div>
            <div className="nav-item" onClick={toCreateJob}>
              {english ? "Create Job" : "新增職缺"}
            </div>
            <div className="nav-item" onClick={toCreateWorkshop}>
              {english ? "Create Workshop" : "新增工作坊"}
            </div>
          </div>
          <img
            src={`${apiUrl}/img/employers/logo/${profilePic}`}
            alt=""
          />
          <div className="nav-item-wrapper">
            <div className="nav-item view-profile" onClick={toHome}>
              {english ? "View Profile" : "個人檔案"}
            </div>
            <div className="nav-item" onClick={toCreateJob}>
              {english ? "Create Job" : "新增職缺"}
            </div>
            <div className="nav-item" onClick={toCreateWorkshop}>
              {english ? "Create Workshop" : "新增工作坊"}
            </div>
          </div>
        </div>
        <div className="nav-item" onClick={() => navigate("/login")}>
          {english ? "Logout" : "登出"}
        </div>
      </div>
    </div>
  );
}

export default NavbarEmployer;
