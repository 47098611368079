import React, { useState, useEffect, useContext } from "react";
import { Context } from "../App.jsx";

function LanguageComponent() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const handleActive = () => {
    setEnglish(!english);
  };
  return (
    <div className="language-component">
      <div className={english ? "active" : ""} onClick={handleActive}>
        English
      </div>
      <div className={english ? "" : "active"} onClick={handleActive}>
        中文
      </div>
    </div>
  );
}

export default LanguageComponent;
