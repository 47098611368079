import React from "react";
import { useNavigate } from "react-router-dom";

function WorkshopCard(props) {
  const navigate = useNavigate();
  const toWorkshopDetails = () => {
    navigate("/workshop/details", { state: props.id });
  }
  return (
    <div className="WorkshopCard" onClick={() => toWorkshopDetails()} >
      <div className="img-container">
        <img src={props.image} alt="" />
      </div>
      <div className="text-container">
        <div className="title">{props.workshopName}</div>
        <div className="details">
          {props.date} | {props.startTime} - {props.endTime} | {props.pax} pax
        </div>
        <div className="details">
          Fee: $80
        </div>
        <div className="hosted-container">Hosted by {props.orgName}</div>
      </div>
    </div>
  );
}

export default WorkshopCard;
