import React, { useState, useEffect, useContext } from "react";
import fileIcon from "./images/fileicon.png";
import { useNavigate, redirect, useLocation } from "react-router-dom";
import LanguageComponent from "./components/LanguageComponent";
import axios from "axios";
import { Context } from "./App";

function SignUpEmployer2() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const navigate = useNavigate();
  // Below is data from previous screen, their login details
  const location = useLocation();
  const { state } = location;
  console.log(state);
  const [selectedFile, setSelectedFile] = useState(null);
  const [finalData, setFinalData] = useState(state);
  const multiFormConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    setFinalData((prev) => {
      return { ...prev, BR: selectedFile };
    });
    console.log("Added BR", finalData);
  }, [selectedFile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedFile) {
      console.log("Have BR");
      axios
        .post(
          apiUrl + "/employer/details",
          finalData,
          // fakeData,
          multiFormConfig
        )
        .then((res) => {
          console.log("")
          console.log(finalData);
          console.log("SIGN UP SUCCEEDED WITH BR");
          navigate("/home/employer");
        })
        .catch((err) => {
          console.log(finalData);
          console.log(multiFormConfig, "Failed");
          alert(
            "Sign Up Failed, Please Check All the Fields are Inputted Correctly"
          );
        });
      console.log(finalData);
      // console.log(fakeData)
    } else {
      alert("No BR Certification");
    }
  };

  return (
    <div className="sign-up-employer">
      <LanguageComponent />
      <div className="left">
        {" "}
        <div className="text-container">
          <div className="flex-header">Flex - Employer</div>
          <div className="flex-subtitle">Create an Account</div>
        </div>
        <div className="decor-container">
          <div className="one circle">1</div>
          <div className="line"></div>
          <div className="two circle">2</div>
          <div className="line-two"></div>
          <div className="three circle">3</div>
        </div>
      </div>
      <div className="right">
        <div className="header">Sign Up</div>
        <div className="container">
          <div className="title">Upload BR Certificate</div>
          <div className="text">
            Your Business Registration Certificate must be uploaded when you
            post your first job. If an invalid Business Registration Certificate
            is uploaded, your account and job post will be suspended.
          </div>
          <div className="sub-text">
            * Business Registration Certificate is internal use only
          </div>
          <form action="" onSubmit={handleSubmit}>
            <div className="img-container">
              <label htmlFor="brCert">
                <img src={fileIcon} alt="" />
              </label>

              <input
                type="file"
                id="brCert"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>

            <div className="sub-text-2">
              Please only upload PNG / JPG / PDF format.Max file upload size is
              10MB.
            </div>
            {selectedFile && <p>Uploaded file: {selectedFile.name}</p>}
            <input type="submit" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUpEmployer2;
