import React, { useEffect, useContext, useState } from "react";
import { useNavigate, redirect } from "react-router-dom";
import fakeImage from "../images/testimage1.jpg";
import axios from "axios"; // Make sure to import axios for API calls
import { Context } from "../App.jsx";

function ReviewComponent(props) {
  const { apiUrl } = useContext(Context);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [finalData, setFinalData] = useState({
    review: "",
    compliment: [],
    rating: 0,
  });
  const [selectedCompliments, setSelectedCompliments] = useState([]);
    const navigate = useNavigate();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
    },
  };
  const compliments = [
    "Quick Learner",
    "Diligent",
    "On-Time",
    "Adaptable",
    "Self-Motivational",
    "Listener",
  ];
  const jobID = props.jobID;
  const userID = props.userID;

  const handleMouseEnter = (value) => {
    setHoverRating(value);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (value) => {
    setRating(value);
    setHoverRating(0);
    setFinalData((prevData) => ({
      ...prevData,
      rating: value, // Update the rating in finalData
    }));
  };

  const handleComplimentClick = (compliment) => {
    setSelectedCompliments((prevSelected) => {
      const updatedSelected = prevSelected.includes(compliment)
        ? prevSelected.filter((item) => item !== compliment)
        : [...prevSelected, compliment];

      // Update finalData with selected compliments
      setFinalData((prevData) => ({
        ...prevData,
        compliment: updatedSelected, // Update the compliments in finalData
      }));

      return updatedSelected;
    });
  };

  const handleTextareaChange = (event) => {
    const { value } = event.target;
    setFinalData((prevData) => ({
      ...prevData,
      review: value, // Update the review in finalData
    }));
  };

  const handleSubmit = () => {
    // Send finalData to the backend
    axios
      .post(
        `${apiUrl}/job/${jobID}/user/${userID}/review/job`,
        finalData,
        config
      ) // Adjust the endpoint as needed
      .then((response) => {
        console.log("Review submitted successfully:", finalData);
        navigate("/home/employer");

        // Optionally reset the form or handle success
      })
      .catch((error) => {
        console.error("Error submitting review:", error);
      });
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={`star ${i <= (hoverRating || rating) ? "filled" : ""}`}
          onMouseEnter={() => handleMouseEnter(i)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(i)}
        >
          ★
        </span>
      );
    }
    return stars;
  };

  return (
    <div className="ReviewComponent">
      <div className="top-container">
        <div className="img-container">
          <img src={props.image} alt="" />
        </div>
        <div className="name">
          {props.name ? props.name : "Name not available"}
        </div>
      </div>
      <div className="sub-title">Send a compliment</div>
      <div className="compliment-wrapper">
        {compliments.map((compliment, index) => (
          <div
            key={index}
            className={`compliment ${
              selectedCompliments.includes(compliment) ? "selected" : ""
            }`}
            onClick={() => handleComplimentClick(compliment)}
          >
            {compliment}
          </div>
        ))}
      </div>
      <div className="sub-title">Leave a Comment</div>
      <textarea
        placeholder="Type your comment here..."
        onChange={handleTextareaChange} // Capture input change
      ></textarea>
      <div className="bottom-container">
        <div className="star-rating-container">
          <div className="sub-title">Give a Star Rating</div>
          <div className="star-rating">{renderStars()}</div>
        </div>

        <div className="submit-review-container">
          <div className="sub-title">Submit Review</div>
          <button className="confirm-button" onClick={handleSubmit}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReviewComponent;
