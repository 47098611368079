import axios from "axios";
import React, { useState, useContext } from "react";
import { useNavigate, redirect } from "react-router-dom";
import { Context } from "./App.jsx";
import LanguageComponent from "./components/LanguageComponent.jsx";

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);

  function toSignUp() {
    navigate("/signup");
  }
  function toForgetPassword() {
    navigate("/forget");
  }
  const [errors, setErrors] = useState({});
  const [employerState, setEmployerState] = useState(true);
  function toggleTrue() {
    setEmployerState(true);
  }
  function toggleFalse() {
    setEmployerState(false);
  }

  function handleSignUpChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.email.trim()) {
      validationErrors.email = "Email Required";
    }
    if (!formData.password.trim()) {
      validationErrors.password = "Password Is Required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password Should Be At Least 8 Characters";
    }
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0 && employerState) {
      axios
        .post(apiUrl + "/user/login", formData)
        .then((res) => {
          console.log(res);
          localStorage.setItem("token", res.data.token);
          navigate("/employee/job");
        })
        .catch((err) => {
          if (err.response.status === 404) {
            alert("Login failed: User not found.");
          } else if (err.respons.status === 401) {
            alert("Login failed: Incorrect username or password");
          } else {
            alert("An expected error occured. Please try again.");
          }
        });
    } else if (Object.keys(validationErrors).length === 0 && !employerState) {
      axios
        .post(apiUrl + "/employer/login", formData)
        .then((res) => {
          console.log(res);
          localStorage.setItem("token", res.data.token);
          navigate("/home/employer");
        })
        .catch((err) => {
          if (err.response.status === 404) {
            alert("Login failed: User not found.");
          } else if (err.respons.status === 401) {
            alert("Login failed: Incorrect username or password");
          } else {
            alert("An expected error occured. Please try again.");
          }
        });
    }
    console.log(formData);
  }

  const workerTitle = (
    <div className="flex-header">
      {english ? "Flex - Employee" : "Flex - 僱員"}
    </div>
  );
  const employerTitle = (
    <div className="flex-header">
      {english ? "Flex - Employer" : "Flex - 僱主"}
    </div>
  );
  const workerFields = (
    <div className="field-wrapper">
      {errors.email && <span>{errors.email}</span>}
      <input
        type="text"
        placeholder="Email Address"
        name="email"
        onChange={handleSignUpChange}
      />
      {errors.password && <span>{errors.password}</span>}
      <input
        type="password"
        placeholder="Password"
        name="password"
        onChange={handleSignUpChange}
      />
    </div>
  );
  const employerFields = (
    <div className="field-wrapper">
      {errors.email && <span>{errors.email}</span>}
      <input
        type="text"
        placeholder="Email Address"
        name="email"
        onChange={handleSignUpChange}
      />
      {errors.password && <span>{errors.password}</span>}
      <input
        type="password"
        placeholder="Password"
        name="password"
        onChange={handleSignUpChange}
      />
    </div>
  );

  return (
    <div className="sign-up-container flex">
      <LanguageComponent />
      <div className="left">
        <div className="wrapper">
          {employerState ? workerTitle : employerTitle}
          <div className="flex-subtitle">
            {english ? "Login to your account" : "登入帳號"}
          </div>
          <div className="flex-body">
            {english
              ? "Flexible jobs at the tip of your fingers"
              : "彈性工作 一鍵擁有"}
          </div>
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          {" "}
          <div className="flex-header">
            {english ? "Login to your account" : "登入帳號"}
          </div>
          <div className="flex tab-container">
            <div
              className={employerState ? "active" : null}
              onClick={toggleTrue}
            >
              {english ? "Employee" : "僱員"}
            </div>
            <div
              className={!employerState ? "active" : null}
              onClick={toggleFalse}
            >
              {english ? "Employer" : "僱主"}
            </div>
          </div>
          <form
            action={apiUrl + "/user/login"}
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="login-container flex-column">
              {employerState ? workerFields : employerFields}
              <input type="submit" value={english ? "Login" : "登入帳號"} />
              <div className="decor">
                <div className="line"></div>
                <div className="text">{english ? "or" : "或"}</div>
                <div className="line"></div>
              </div>
              <div className="login-button" onClick={toSignUp}>
                {english ? "Sign Up" : "登記"}
              </div>
              <div className="forgot-password">
                {english ? "Forgot Password? " : "忘記帳戶？"}
                <span onClick={toForgetPassword}>
                  {english ? "Click Here" : "按這裡"}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
