import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, redirect } from "react-router-dom";
import LanguageComponent from "./components/LanguageComponent.jsx";
import { Context } from "./App.jsx";

function SignUp() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const navigate = useNavigate();

  function toSignUp() {
    navigate("/login");
  }
  function toEmployeeSignUp() {
    navigate("/signup/employee");
  }
  const [formData, setFormData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [employerState, setEmployerState] = useState(true);
  function toggleTrue() {
    setEmployerState(true);
  }
  function toggleFalse() {
    setEmployerState(false);
  }

  function handleSignUpChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }
  var userToken = "";
  function handleSubmit(e) {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.companyName.trim() && !employerState) {
      validationErrors.companyName = "Company Name Required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email Required";
    }
    if (!formData.password.trim()) {
      validationErrors.password = "Password Is Required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password Should Be At Least 8 Characters";
    }

    if (formData.password != formData.confirmPassword) {
      validationErrors.confirmPassword = "Password Does Not Match";
    }
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      console.log(validationErrors);
    }
    if (Object.keys(validationErrors).length === 0 && employerState) {
      axios
        .post(apiUrl + "/user/signup", formData)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          navigate("/user/details/new");
        })
        .catch((err) => {
          console.log(err, "error msg");
          alert("Account Already Exists or Email is Incorrect");
        });
    } else if (Object.keys(validationErrors).length === 0 && !employerState) {
      axios
        .post(apiUrl + "/employer/signup", formData)
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          navigate("/employer/details", { state: formData });
        })
        .catch((err) => console.log(err));
    }
    console.log(formData);
  }

  const workerTitle = (
    <div className="flex-header">
      {english ? "Flex - Employee" : "Flex - 僱員"}{" "}
    </div>
  );
  const employerTitle = (
    <div className="flex-header">
      {english ? "Flex - Employer" : "Flex - 僱主"}
    </div>
  );
  const workerFields = (
    <div className="field-wrapper">
      {errors.email && <span>{errors.email}</span>}
      <input
        type="text"
        placeholder={english ? "Email Address" : "電郵地址"}
        name="email"
        onChange={handleSignUpChange}
      />
      {errors.password && <span>{errors.password}</span>}
      <input
        type="password"
        placeholder={english ? "Password" : "密碼"}
        name="password"
        onChange={handleSignUpChange}
      />
      {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
      <input
        type="password"
        placeholder={english ? "Re-type Your Password" : "確認密碼"}
        name="confirmPassword"
        onChange={handleSignUpChange}
      />
    </div>
  );
  const employerFields = (
    <div className="field-wrapper">
      {errors.companyName && <span>{errors.companyName}</span>}
      <input
        type="text"
        placeholder={english ? "Company Name" : "公司名稱"}
        name="companyName"
        id="compname"
        onChange={handleSignUpChange}
      />
      {errors.email && <span>{errors.email}</span>}
      <input
        type="text"
        placeholder={english ? "Email Address" : "電郵地址"}
        name="email"
        onChange={handleSignUpChange}
      />
      {errors.password && <span>{errors.password}</span>}
      <input
        type="password"
        placeholder={english ? "Password" : "密碼"}
        name="password"
        onChange={handleSignUpChange}
      />
      {errors.confirmPassword && <span>{errors.confirmPassword}</span>}
      <input
        type="password"
        placeholder={english ? "Re-type Password" : "確認密碼"}
        name="confirmPassword"
        onChange={handleSignUpChange}
      />
    </div>
  );

  return (
    <div className="sign-up-container flex">
      <LanguageComponent />
      <div className="left">
        <div className="wrapper">
          {employerState ? workerTitle : employerTitle}
          <div className="flex-subtitle">
            {english ? "Create an account" : "建立帳戶"}
          </div>
          <div className="flex-body">
            {english
              ? "Flexible jobs at a press of your fingers"
              : "彈性工作 一鍵擁有"}{" "}
          </div>
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          {" "}
          <div className="flex-header">{english ? "Sign Up" : "建立帳戶"}</div>
          <div className="flex tab-container">
            <div
              className={employerState ? "active" : null}
              onClick={toggleTrue}
            >
              {english ? "Employee" : "僱員"}
            </div>
            <div
              className={!employerState ? "active" : null}
              onClick={toggleFalse}
            >
              {english ? "Employer" : "僱主"}
            </div>
          </div>
          <form
            // action={
            //   employerState
            //     ? "http://localhost:4000/user/signup"
            //     : "http://localhost:3000/employer"
            // }
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="login-container flex-column">
              {employerState ? workerFields : employerFields}
              <input
                type="submit"
                value={english ? "Create Account" : "建立帳戶"}
              />
              <div className="decor">
                <div className="line"></div>
                <div className="text">{english ? "or" : "或"}</div>
                <div className="line"></div>
              </div>
              <div className="login-button" onClick={toSignUp}>
                {english ? "Login" : "登入"}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
