import React, { useState, useEffect, useContext } from "react";
import { Context } from "./App.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMoneyBill,
  faLayerGroup,
  faLocationDot,
  faCommentsDollar,
  faComment,
  faTrainSubway,
  faCircleExclamation,
  faCommentDots,
  faSeedling,
  faCalendar,
  faCalendarDays,
  faUsers,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import shirt from "./images/shirt.png";
import map from "./images/map.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import companyIcon from "./images/companyDefault.jpg";
import LanguageComponent from "./components/LanguageComponent.jsx";

function ViewJobDetails(props) {
  const navigate = useNavigate();

  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const location = useLocation();
  const jobId = location.state;
  const [companyName, setCompanyName] = useState("");
  const [jobName, setJobName] = useState("");
  const [description, setDescription] = useState("");
  const [pay, setPay] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [requirement, setRequirement] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [modal, setmodal] = useState(false);
  const [tag, setTag] = useState("");
  const [date, setDate] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [userData, setUserData] = useState([]);
  const [applied, setApplied] = useState(false);
  const [userJobArray, setUserJobArray] = useState([]);

  // const config = {
  //   headers: { Authorization: `Bearer ${localStorage.token}` },
  // };

  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };

  const handleOverlay = () => {
    setmodal(!modal);
  };

  useEffect(() => {
    axios.get(apiUrl  + `/job/${jobId}`, config).then((res) => {
      const dataPath = res.data.data.jobs;
      console.log(dataPath);

      setStartTime(dataPath.startTime);
      setEndTime(dataPath.endTime);
      setCompanyName(dataPath.company.companyName);
      setJobName(dataPath.jobTitle);
      setDescription(dataPath.description);
      setPay(dataPath.pay);
      setJobLocation(dataPath.location);
      setRequirement(dataPath.requirement);
      setTag(dataPath.tags);
      setDate(dataPath.date);
      setVacancy(dataPath.vacancy);
      console.log("getting data from jobID");
    });
    axios.get(apiUrl + "/user/me", config).then((res) => {
      // console.log("Getting data from profile");
      console.log(res);
      console.log(res.data.data.jobs);
      var dataPath = res.data.data.applied_jobs;
      setUserData(dataPath);
      // console.log(dataPath.length);

      if (dataPath.length > 0) {
        console.log("There is something");
        setUserJobArray([...dataPath.map((item) => item._id)]);
      } else {
        console.log("Zero jobs applied");
      }
    });
  }, []);
  const checkJobExists = () => {
    console.log(userJobArray);
    console.log(jobId);
    if (userJobArray.includes(jobId)) {
      setApplied(true);
      console.log("JOB IS ALREADY APPLIED");
    } else {
      console.log("Job has not been applied yet");
    }
  };
  useEffect(() => {
    checkJobExists();
  }, [userJobArray]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const applyForJob = () => {
    axios
      .post(apiUrl + `/job/${jobId}/booking/job`, null, config)
      .then((res) => {
        console.log(res);
        console.log("Successfully posted job");
        navigate("/user/profile/temp");
      })
      .catch((err) => {
        console.log(
          "Failed to post at",
          apiUrl + `/job/${jobId}/booking/job`
        );
        console.log(config);
        console.log(err);
        navigate("/employee/job");
      });
  };

  const applyJobOverlay = (
    <div className="div">
      <div className="overlay" onClick={handleOverlay}></div>
      <div className="white-container">
        <div className="title">確定應徵?</div>
        <img src={companyIcon} alt="" />
        <div className="body-text">
          {" "}
          {`${companyName}`} - {`${jobName}`}
        </div>

        <div className="body-text">
          {date} | {startTime} - {endTime} | ${pay}/hr
        </div>
        <div className="button-container">
          <button onClick={handleOverlay}>取消</button>
          <button onClick={applyForJob}>確定</button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="job-details-container">
      <LanguageComponent />
      {modal ? applyJobOverlay : null}
      <div className="top-container">
        <Link to="/employee/job">
          <button>回去</button>
        </Link>
        <div className="job-title h1">
          {" "}
          {`${companyName}`} - {`${jobName}`}{" "}
        </div>
      </div>
      <div className="job-container">
        <div className="left">
          <div className="job-summary">
            <div className="title h2">
              {english ? "Job Details" : "工作簡介"}
            </div>
            <div className="summary-items">
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faCalendarDays} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Date" : "日期"}</div>
                  <div className="pay">{date}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Time" : "時間"}</div>
                  <div className="pay">
                    {startTime} - {endTime}
                  </div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faCommentsDollar} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Pay" : "薪資"}</div>
                  <div className="pay">${`${pay}`} / Hour</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="text">
                  <div className="subtitle h3">
                    {" "}
                    {english ? "Location" : "地點"}
                  </div>
                  <div className="pay">{jobLocation}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="text">
                  <div className="subtitle h3">
                    {english ? "Number of Vacancy" : "職位空缺"}
                  </div>
                  <div className="pay">{vacancy}</div>
                </div>
              </div>
              <div className="summary-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faLayerGroup} />
                </div>
                <div className="text">
                  <div className="subtitle h3">{english ? "Date" : "日期"}</div>
                  <div className="pay">{tag}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="job-detail">
            <div className="title h2">
              {english ? "Job Details" : "工作詳情"}
            </div>
            <div className="detail-text">{description}</div>
          </div>
          <div className="dress-code">
            <div className="h2"> {english ? "Dress Code" : "著裝要求"}</div>
            <div className="img-container">
              <swiper-container
                slides-per-view="3"
                navigation="true"
                pagination="true"
                speed="500"
                css-mode="true"
                style={{
                  "--swiper-navigation-color": "black",
                  "--swiper-pagination-color": "black",
                }}
                breakpoints='{
                  "1024": {
                    "slidesPerView": 3
                  },
                   "580": {
                    "slidesPerView": 2
                  },
                  "0": {
                    "slidesPerView": 1
                  }
                }'
              >
                <swiper-slide>
                  <img src={shirt} alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src={shirt} alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src={shirt} alt="" />
                </swiper-slide>
                <swiper-slide>
                  <img src={shirt} alt="" />
                </swiper-slide>
              </swiper-container>
            </div>
          </div>
          <div className="allowance">
            <div className="title h2">{english ? "Requirements" : "要求"}</div>
            <div className="requirement-item">
              <div className="icon">
                <FontAwesomeIcon icon={faSeedling} />
              </div>
              <div className="text">
                <div className="subtitle h3">{requirement}</div>
                <div className="pay"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewJobDetails;
